/* tslint:disable */
/* eslint-disable */
/**
 * HKA-APP News Develop-Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface GetNewsCategoryDto
 */
export interface GetNewsCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof GetNewsCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    format?: GetNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {NewsCategoryFacultyDto}
     * @memberof GetNewsCategoryDto
     */
    faculty?: NewsCategoryFacultyDto;
}

/**
    * @export
    * @enum {string}
    */
export enum GetNewsCategoryDtoFormatEnum {
    Rss = 'RSS',
    Json = 'JSON'
}

/**
 * 
 * @export
 * @interface GetNewsDto
 */
export interface GetNewsDto {
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    iwiNewsId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    permalink?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    thumbnail?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    author?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    date?: number;
    /**
     * 
     * @type {GetNewsCategoryDto}
     * @memberof GetNewsDto
     */
    category?: GetNewsCategoryDto;
}
/**
 * 
 * @export
 * @interface NewsCategoryFacultyDto
 */
export interface NewsCategoryFacultyDto {
    /**
     * 
     * @type {string}
     * @memberof NewsCategoryFacultyDto
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface PostNewsCategoryDto
 */
export interface PostNewsCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    format?: PostNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    faculty?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostNewsCategoryDtoFormatEnum {
    Rss = 'RSS',
    Json = 'JSON'
}

/**
 * 
 * @export
 * @interface PutNewsCategoryDto
 */
export interface PutNewsCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof PutNewsCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    format?: PutNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    faculty?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PutNewsCategoryDtoFormatEnum {
    Rss = 'RSS',
    Json = 'JSON'
}

/**
 * 
 * @export
 * @interface V2GetNewsCategoryDto
 */
export interface V2GetNewsCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof V2GetNewsCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    format?: V2GetNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsCategoryDto
     */
    facultyId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum V2GetNewsCategoryDtoFormatEnum {
    Rss = 'RSS',
    Json = 'JSON'
}

/**
 * 
 * @export
 * @interface V2GetNewsDto
 */
export interface V2GetNewsDto {
    /**
     * 
     * @type {number}
     * @memberof V2GetNewsDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof V2GetNewsDto
     */
    iwiNewsId?: number;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    permalink?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof V2GetNewsDto
     */
    date?: string;
    /**
     * 
     * @type {V2GetNewsCategoryDto}
     * @memberof V2GetNewsDto
     */
    category?: V2GetNewsCategoryDto;
}

/**
 * NewsApiApi - axios parameter creator
 * @export
 */
export const NewsApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsCategory1: async (postNewsCategoryDto: PostNewsCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postNewsCategoryDto' is not null or undefined
            assertParamExists('createNewsCategory1', 'postNewsCategoryDto', postNewsCategoryDto)
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNewsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an existing news category, this cascades down to corresponding news
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsCategory: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteNewsCategory', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNewsCategories1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNews1', 'id', id)
            const localVarPath = `/api/news/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available news for a given category
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsByCategory1: async (categoryId: number, sendAll?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsByCategory1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (sendAll !== undefined) {
                localVarQueryParameter['sendAll'] = sendAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategory: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsCategory', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategoryDefaultImage1: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsCategoryDefaultImage1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/image/{categoryId}.png`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of news since given date for a given category, FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} categoryId 
         * @param {string} updatedSince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsSinceDateByCategory1: async (categoryId: number, updatedSince: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsSinceDateByCategory1', 'categoryId', categoryId)
            // verify required parameter 'updatedSince' is not null or undefined
            assertParamExists('getNewsSinceDateByCategory1', 'updatedSince', updatedSince)
            const localVarPath = `/api/news/v1/since`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (updatedSince !== undefined) {
                localVarQueryParameter['updatedSince'] = updatedSince;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailImage1: async (newsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('getThumbnailImage1', 'newsId', newsId)
            const localVarPath = `/api/news/v1/thumbnails/{newsId}`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an existing news category, image must be a base64-encoded image, imageUrl will be set by server
         * @param {PutNewsCategoryDto} putNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsCategory1: async (putNewsCategoryDto: PutNewsCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'putNewsCategoryDto' is not null or undefined
            assertParamExists('updateNewsCategory1', 'putNewsCategoryDto', putNewsCategoryDto)
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNewsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApiApi - functional programming interface
 * @export
 */
export const NewsApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewsCategory1(postNewsCategoryDto: PostNewsCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewsCategory1(postNewsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an existing news category, this cascades down to corresponding news
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsCategory(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNewsCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNewsCategories1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNewsCategories1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNews1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNews1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available news for a given category
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsByCategory1(categoryId, sendAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCategory(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCategoryDefaultImage1(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCategoryDefaultImage1(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of news since given date for a given category, FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} categoryId 
         * @param {string} updatedSince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsSinceDateByCategory1(categoryId, updatedSince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThumbnailImage1(newsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThumbnailImage1(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an existing news category, image must be a base64-encoded image, imageUrl will be set by server
         * @param {PutNewsCategoryDto} putNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNewsCategory1(putNewsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApiApi - factory interface
 * @export
 */
export const NewsApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsCategory1(postNewsCategoryDto: PostNewsCategoryDto, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.createNewsCategory1(postNewsCategoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an existing news category, this cascades down to corresponding news
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsCategory(categoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNewsCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNewsCategories1(options?: any): AxiosPromise<Array<GetNewsCategoryDto>> {
            return localVarFp.getAllNewsCategories1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews1(id: number, options?: any): AxiosPromise<GetNewsDto> {
            return localVarFp.getNews1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available news for a given category
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any): AxiosPromise<Array<GetNewsDto>> {
            return localVarFp.getNewsByCategory1(categoryId, sendAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategory(categoryId: number, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.getNewsCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategoryDefaultImage1(categoryId: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getNewsCategoryDefaultImage1(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of news since given date for a given category, FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} categoryId 
         * @param {string} updatedSince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any): AxiosPromise<Array<GetNewsDto>> {
            return localVarFp.getNewsSinceDateByCategory1(categoryId, updatedSince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} newsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailImage1(newsId: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getThumbnailImage1(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an existing news category, image must be a base64-encoded image, imageUrl will be set by server
         * @param {PutNewsCategoryDto} putNewsCategoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.updateNewsCategory1(putNewsCategoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApiApi - object-oriented interface
 * @export
 * @class NewsApiApi
 * @extends {BaseAPI}
 */
export class NewsApiApi extends BaseAPI {
    /**
     * 
     * @summary create a new news category
     * @param {PostNewsCategoryDto} postNewsCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public createNewsCategory1(postNewsCategoryDto: PostNewsCategoryDto, options?: any) {
        return NewsApiApiFp(this.configuration).createNewsCategory1(postNewsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an existing news category, this cascades down to corresponding news
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public deleteNewsCategory(categoryId: number, options?: any) {
        return NewsApiApiFp(this.configuration).deleteNewsCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all existing news categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getAllNewsCategories1(options?: any) {
        return NewsApiApiFp(this.configuration).getAllNewsCategories1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a news for a given id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getNews1(id: number, options?: any) {
        return NewsApiApiFp(this.configuration).getNews1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available news for a given category
     * @param {number} categoryId 
     * @param {boolean} [sendAll] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any) {
        return NewsApiApiFp(this.configuration).getNewsByCategory1(categoryId, sendAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a news category for a given id
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getNewsCategory(categoryId: number, options?: any) {
        return NewsApiApiFp(this.configuration).getNewsCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the default image for given news category
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getNewsCategoryDefaultImage1(categoryId: number, options?: any) {
        return NewsApiApiFp(this.configuration).getNewsCategoryDefaultImage1(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of news since given date for a given category, FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
     * @param {number} categoryId 
     * @param {string} updatedSince 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any) {
        return NewsApiApiFp(this.configuration).getNewsSinceDateByCategory1(categoryId, updatedSince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} newsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public getThumbnailImage1(newsId: number, options?: any) {
        return NewsApiApiFp(this.configuration).getThumbnailImage1(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an existing news category, image must be a base64-encoded image, imageUrl will be set by server
     * @param {PutNewsCategoryDto} putNewsCategoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiApi
     */
    public updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any) {
        return NewsApiApiFp(this.configuration).updateNewsCategory1(putNewsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApiV2Api - axios parameter creator
 * @export
 */
export const NewsApiV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary A list of all available news in the version 2 dto
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsV2: async (categoryId: number, sendAll?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsV2', 'categoryId', categoryId)
            const localVarPath = `/api/news/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (sendAll !== undefined) {
                localVarQueryParameter['sendAll'] = sendAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApiV2Api - functional programming interface
 * @export
 */
export const NewsApiV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary A list of all available news in the version 2 dto
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsV2(categoryId: number, sendAll?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V2GetNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsV2(categoryId, sendAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApiV2Api - factory interface
 * @export
 */
export const NewsApiV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary A list of all available news in the version 2 dto
         * @param {number} categoryId 
         * @param {boolean} [sendAll] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsV2(categoryId: number, sendAll?: boolean, options?: any): AxiosPromise<Array<V2GetNewsDto>> {
            return localVarFp.getNewsV2(categoryId, sendAll, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApiV2Api - object-oriented interface
 * @export
 * @class NewsApiV2Api
 * @extends {BaseAPI}
 */
export class NewsApiV2Api extends BaseAPI {
    /**
     * 
     * @summary A list of all available news in the version 2 dto
     * @param {number} categoryId 
     * @param {boolean} [sendAll] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiV2Api
     */
    public getNewsV2(categoryId: number, sendAll?: boolean, options?: any) {
        return NewsApiV2ApiFp(this.configuration).getNewsV2(categoryId, sendAll, options).then((request) => request(this.axios, this.basePath));
    }
}


