// eslint-disable-next-line camelcase
import {  PersonsV1Api } from '@/api-client/broker'

export const namespaced = true

export const state = {
  persons: [],
  person: null,
}

const PersonApi = new PersonsV1Api()

export const mutations = {
  SET_PERSONS(state, response) {
    state.persons = response
  },
  SET_PERSON(state, response) {
    state.person = response
  },
}

export const actions = {
  async getAllPersons({ commit, dispatch, rootState }) {
    if (rootState.campus.buildings.length === 0) {
      await dispatch('campus/getAllBuildings', null, { root: true })
    }

    if (rootState.campus.rooms.length === 0) {
      await dispatch('campus/getAllRooms', null, { root: true })
    }

    const response = await PersonApi.getAllPersons1()

    commit(
      'SET_PERSONS',
      response.data.map((person) => {
        if (person.room) {
          person.room = rootState.campus.rooms.find(
            (room) => room.id === person.room.id
          )
        }

        return { ...person, type: 'PERSON' }
      })
    )
  },
  async getPersonById({ commit, getters }, id) {
    const person = await getters.getPersonById(id)

    if (person) {
      commit('SET_PERSON', person)
    } else {
      return await PersonApi.getPerson(id).then((response) => {
        commit('SET_PERSON', response)
      })
    }
  },
}

export const getters = {
  getPersonById: (state) => (id) => {
    return state.persons.find((person) => person.id === id)
  },
}
