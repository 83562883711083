import {
  LearningPlaceOccupationApiApi,
  LearningPlaceApiApi,
} from '@/api-client/learningplace'

const LearningPlaceApi = new LearningPlaceApiApi()
const learningPlacesOccupationApi = new LearningPlaceOccupationApiApi()


export const namespaced = true

export const state = {
  learningPlaces: [],
  learningPlacesOccupation: [],
}

export const mutations = {
  SET_LEARNINGPLACES(state, response) {
    state.learningPlaces = response
  },
  SET_LEARNINGPLACESOCCUPATION(state, response) {
    state.learningPlacesOccupation = response
  },
}

export const actions = {
  async getAllLearningPlaces({ commit }) {
    return await LearningPlaceApi.getAllLearningPlaces().then((response) => {
      commit('SET_LEARNINGPLACES', response.data)
    })
  },
  async getAllLearningPlacesOccupation({ commit }) {
    return await learningPlacesOccupationApi
      .getAllLearningPlaceOccupations()
      .then((response) => {
        commit('SET_LEARNINGPLACESOCCUPATION', response.data)
      })
  },
}
