import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  const { router } = app
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-ZTN1SSXLTV' },
    },
    router
  )
}
