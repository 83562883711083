export default {
  welcome: 'Welcome',
  // CATEGORIZED BY FILE

  SelectLanguage: 'Language',

  // Toolbar
  News: 'News',
  Freshman: 'Study Help',
  Campus: 'Campus',
  People: 'People',
  Canteen: 'Canteen',
  Timetable: 'Timetable',
  LearningPlaces: 'Learning Places',
  TrainStations: 'Train Stations',
  InstallApp: 'Install App?',
  Faculty: 'Faculty',
  FacultyShort: 'Fac',

  // CategoryMasonry
  NoEntryCategory: 'Sorry, there is no entry in this category',

  // FormGenerator
  Other: 'Other',

  // FAQ
  RequestQuestion: 'Why does it take so long for my request to be processed',
  RequestAnswer:
    'The support team of HKA-APP is very anxious to give you a qualitative and helpful answer to your request as soon as possible. We ask for your patience because HKA-APP is a small team of students who realize the project parallel to their studies. Due to the continuous re-staffing of the respective areas after each semester, additional longer processing times may occur.',
  BrowserQuestion: 'Which Browser can i use for the Web App',
  BrowserAnswer:
    'The Web App runs on every browser that supports JavaScript: Google Chrome, Mozilla Firefox and Microsoft Edge ',
  ServerQuestion:
    'When requesting server data, I get an error. What should I do',
  ServerAnswer:
    'Wait a few hours. It is probably a server error. Otherwise, send us an email to info@hskampus.de or contact us via the feedback tab in the footer',
  QuestionPersonData:
    'Why are some room numbers and data about persons incorrect?',
  AnswerPersonData:
    'The data is forwarded from the LSF server of Karlsruhe University of Applied Sciences. Unfortunately, they may not be updated promptly from time to time.',
  QuestionTimetable: 'Why is my timetable not available or not selectable',
  AnswerTimetable:
    'HKA-APP only serves as an intermediary, which means that we qualitatively process the data that we receive from various sources. We, therefore, do not influence the sources, so it may be that no or incorrect data is displayed. Please get in touch with the respective faculty.',
  QuestionSettings: 'Why are my settings not persisted??',
  AnswerSettings:
    'In the current version we save no cookies and therefore no settings.',
  QuestionNotAnswered:
    'Couldn not find an answer to your question? Then we would be happy to receive an e-mail:',
  AnswerMostImportant:
    'Here you will also find the most important answers to your questions about the HKA APP:',
  ToFaq: 'To the detailed FAQ',

  // News Page
  FilterNews: 'Filter News',
  SearchNews: 'Search News',
  Reports: 'Reports',
  Press: 'Press',
  ToArticle: 'To Article',
  PublishedAt: 'Published at',
  Feedback: 'Feedback',
  Impressum: 'Impress',
  FAQ: 'FAQ',

  // Campus
  General: 'General',
  Office: 'Office',
  StudentAssociation: 'Student Association',

  // Office Card
  Room: 'Room',
  Phone: 'Phone',
  ConsultationHours: 'Consultation Hours',
  LearnMore: 'Learn More',
  Edit: 'Edit',
  Close: 'Close',
  LastModified: 'Last Modified',

  // People
  ChooseFaculty: 'Choose Faculty',
  SearchForPeople: 'Search For People',

  // edit

  PersonEdit: 'Edit person',
  RequestChange: 'Request change',

  // Canteen
  SelectLocation: 'Select Location',
  SelectRole: 'Select Role',
  SelectDate: 'Select Date',
  LocationClosed: 'No information for this day!',
  Closed: 'Closed',
  Today: 'Today',
  Undergraduate: 'Undergraduate',
  Guest: 'Guest',
  Student: 'Student',
  Employee: 'Employee',

  Wahlessen1: 'Elective Dinner 1',
  Wahlessen2: 'Elective Dinner 2',
  Wahlessen3: 'Election 3',
  Linie1: 'Line 1',
  Linie2: 'Line 2',
  Linie3: 'Line 3',
  Linie45: 'Line 4/5',
  L6Update: 'Line 6 Update',
  l6: 'L6',
  l5: 'L5',
  CurryQueen: 'Curry Queen',
  GutGünstig: 'Good & Cheap',
  Schnitzelbar: 'Cutlet Bar',
  Aktionstheke: 'Promotional Counter',
  wahl3: 'Elective Dinner 3',
  GutGünstig2: 'Good & Cheap 2',
  Cafeteriaab1430: 'Cafeteria from 2:30pm',
  CafeteriaHeißeTheke: 'Cafeteria Hot Counter',
  Abend: 'Evening',
  pizza: 'Pizza',
  pasta: 'Pasta',
  Buffet: 'Buffet',
  salat_dessert: 'Salad / Dessert',

  // Timetable
  Calendar: 'Timetable',
  SelectStudyProgram: 'Please select a study program',
  SelectedSemester: 'Semester',
  SelectSemester: 'Select Timetable',
  GeneralDates: 'Semester plan',
  TaskListFreshman: 'Task list',
  ResetTaskList: 'Reset task list',
  ResetText:
    'When you reset the schedule, the appointments you moved in the task list for freshman students will be reset to the original state. Do you still want to reset?',
  Cancel: 'Cancel',
  Reset: 'Reset',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  MoveDate: 'Postpone date',
  Done: 'Done',
  Open: 'Open',
  Move: 'Move',
  DateMoved: 'Date was successfully postponed',
  TaskCompleted: 'Task completed',
  TaskReopened: 'Task reopened',
  NoData: 'First select a study program',
  TimetableSelect: 'Timetable',
  Confirm: 'Confirm',
  InformationFirstSemester:
    'For data protection reasons, only the first semester of the respective degree program can currently be displayed.',

  // Timetable for MMT
  Username: 'Username',
  Password: 'Password',
  ShowMMTPlans: 'Show MMT Plans',
  For: 'For',
  Students: 'Students',
  InfoTextMMT:
    'Open the link via the "View MMT Schedules" button to view the calendar on the Untis website.',
  InfoTextW: 'The calendar can be accessed via InfoTools.',
  Course: 'Course',
  Semester: 'Semester',

  // Learning Places
  DataIsLoadingMessage: 'Data is being loaded',
  DataLoadingFailedMessage: 'An error occured while loading',
  SearchForPlaces: 'Search for learning places',
  OpenLPsOnly: 'Open LPs only',
  AllLPs: 'All LPs',
  Of: 'of',
  PlacesFree: 'places free',
  OpeningHours: 'Opening Hours',
  OpeningHoursInfo:
    'Before and during the examination period also open on Sundays and holidays according to special announcement.',
  Building: 'Building',
  Floor: 'Floor',

  // Train Stations
  CloseStations: 'Stops close to the location',
  CloseToUniStations: 'Stops near the university',
  SubbedStations: 'Subscribed Stations',
  Timetables: 'Timetables',
  CityBus: 'City bus',
  BUS: 'BUS',
  Nearby: 'Nearby',
  WholeNetwork: 'In the whole network',
  StationNameSearch: 'Stop name',
  Search: 'Search',

  // Feedback
  FeedbackRequest: 'Feedback or support request',
  FeedbackText:
    'We, the team behind HKA-APP, are students of the Karlsruhe University of Applied Sciences. We develop this app with care and dedication. Yet, errors can happen. That is why we are grateful for any feedback, the contribution of our users really helps us making the product better.',
  Support: 'Support',

  // Image alt texts
  HKAAppLogo: 'HKA-APP Logo',
}
