import Vue from 'vue'
import Vuex from 'vuex'
import { createDirectStore } from 'direct-vuex'
import * as common from '@/store/-modules/common'
import * as news from '@/store/-modules/news'
import * as person from '@/store/-modules/person'
import * as location from '@/store/-modules/location'
import * as learningPlace from '@/store/-modules/learningPlace'
import * as campus from '@/store/-modules/campus'
import * as canteen from '@/store/-modules/canteen'

Vue.use(Vuex)

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules: {
    common,
    canteen,
    news,
    person,
    location,
    learningPlace,
    campus,
  },
  state: {},
})

export default store

export { rootActionContext, moduleActionContext }

export type AppStore = typeof store

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: AppStore
  }
}
