/* tslint:disable */
/* eslint-disable */
/**
 * HsKampus2-Server Api-Doc production
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BuildProperties
 */
export interface BuildProperties {
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    artifact?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    coordinatesCenter: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    houseNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    postcode: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {LdapFaculty}
     * @memberof Course
     */
    faculty?: LdapFaculty;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    lsfId?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Message from server
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface Ersti
 */
export interface Ersti {
    /**
     * 
     * @type {number}
     * @memberof Ersti
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Ersti
     */
    izAbbreviation?: string;
}
/**
 * 
 * @export
 * @interface GetBuildingDto
 */
export interface GetBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof GetBuildingDto
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBuildingDto
     */
    coordinatesCenter?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBuildingDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetBuildingDto
     */
    houseNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBuildingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBuildingDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBuildingDto
     */
    postcode?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBuildingDto
     */
    street?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBuildingDto
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetChangeRequestDto
 */
export interface GetChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GetChangeRequestDto
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetChangeRequestDto
     */
    hasImage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChangeRequestDto
     */
    hsUser?: string;
    /**
     * 
     * @type {number}
     * @memberof GetChangeRequestDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetChangeRequestDto
     */
    objectId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetChangeRequestDto
     */
    type?: GetChangeRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetChangeRequestDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChangeRequestDto
     */
    validationImage?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetChangeRequestDtoTypeEnum {
    Course = 'COURSE',
    General = 'GENERAL',
    Learningplace = 'LEARNINGPLACE',
    Office = 'OFFICE',
    Person = 'PERSON',
    Studentunion = 'STUDENTUNION'
}

/**
 * 
 * @export
 * @interface GetConfigurationDto
 */
export interface GetConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof GetConfigurationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetConfigurationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConfigurationDto
     */
    property?: string;
}
/**
 * 
 * @export
 * @interface GetCourseDto
 */
export interface GetCourseDto {
    /**
     * 
     * @type {LdapFacultyDto}
     * @memberof GetCourseDto
     */
    faculty?: LdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof GetCourseDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCourseDto
     */
    lsfId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCourseDto
     */
    name?: string;
    /**
     * 
     * @type {Array<GetSemesterDto>}
     * @memberof GetCourseDto
     */
    semesters?: Array<GetSemesterDto>;
}
/**
 * 
 * @export
 * @interface GetLdapCourseDto
 */
export interface GetLdapCourseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLdapCourseDto
     */
    faculty: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapCourseDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapCourseDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface GetLdapFacultyDto
 */
export interface GetLdapFacultyDto {
    /**
     * 
     * @type {string}
     * @memberof GetLdapFacultyDto
     */
    alias?: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetLdapFacultyDto
     */
    courses?: Array<IdWrapper>;
    /**
     * 
     * @type {boolean}
     * @memberof GetLdapFacultyDto
     */
    faculty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLdapFacultyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapFacultyDto
     */
    name: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetLdapFacultyDto
     */
    newsCategories?: Array<IdWrapper>;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetLdapFacultyDto
     */
    poiCourses?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface GetLdapPersonDto
 */
export interface GetLdapPersonDto {
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    course?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLdapPersonDto
     */
    ersti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    faculty?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLdapPersonDto
     */
    isStudent: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLdapPersonDto
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof GetLdapPersonDto
     */
    personId?: number;
}
/**
 * 
 * @export
 * @interface GetLearningPlaceDto
 */
export interface GetLearningPlaceDto {
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    availableSeats?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    building: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLearningPlaceDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    latitude: number;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    longName: string;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    openingHours?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    presentationIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    roomLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    roomName: string;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    shortName?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLearningPlaceDto
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface GetLearningPlaceOccupationDto
 */
export interface GetLearningPlaceOccupationDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLearningPlaceOccupationDto
     */
    closed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetLearningPlaceOccupationDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceOccupationDto
     */
    freeSeats?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceOccupationDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceOccupationDto
     */
    occupiedSeats?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceOccupationDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLearningPlaceOccupationDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface GetNewsCategoryDto
 */
export interface GetNewsCategoryDto {
    /**
     * 
     * @type {LdapFacultyDto}
     * @memberof GetNewsCategoryDto
     */
    faculty?: LdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    format?: GetNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof GetNewsCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsCategoryDto
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetNewsCategoryDtoFormatEnum {
    Json = 'JSON',
    Rss = 'RSS'
}

/**
 * 
 * @export
 * @interface GetNewsDto
 */
export interface GetNewsDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    author?: string;
    /**
     * 
     * @type {NewsCategory}
     * @memberof GetNewsDto
     */
    category?: NewsCategory;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    iwiNewsId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    permalink?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNewsDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNewsDto
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetPersonDto
 */
export interface GetPersonDto {
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    academicDegree?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    consultationHour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    faculty?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    firstName?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPersonDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPersonDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    ldap?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    remark?: string;
    /**
     * 
     * @type {GetRoomDto}
     * @memberof GetPersonDto
     */
    room?: GetRoomDto;
    /**
     * 
     * @type {string}
     * @memberof GetPersonDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPersonDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPersonDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface GetPoiCourseDto
 */
export interface GetPoiCourseDto {
    /**
     * 
     * @type {GetBuildingDto}
     * @memberof GetPoiCourseDto
     */
    building?: GetBuildingDto;
    /**
     * 
     * @type {LdapFacultyRes}
     * @memberof GetPoiCourseDto
     */
    faculty?: LdapFacultyRes;
    /**
     * 
     * @type {number}
     * @memberof GetPoiCourseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiCourseDto
     */
    name?: string;
    /**
     * 
     * @type {GetPoiOfficeDtoRes}
     * @memberof GetPoiCourseDto
     */
    office?: GetPoiOfficeDtoRes;
    /**
     * 
     * @type {GetPoiStudentUnionDto}
     * @memberof GetPoiCourseDto
     */
    studentUnion?: GetPoiStudentUnionDto;
    /**
     * 
     * @type {number}
     * @memberof GetPoiCourseDto
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetPoiGeneralDto
 */
export interface GetPoiGeneralDto {
    /**
     * 
     * @type {string}
     * @memberof GetPoiGeneralDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof GetPoiGeneralDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof GetPoiGeneralDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiGeneralDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiGeneralDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiGeneralDto
     */
    phone?: string;
    /**
     * 
     * @type {GetRoomDto}
     * @memberof GetPoiGeneralDto
     */
    room?: GetRoomDto;
    /**
     * 
     * @type {number}
     * @memberof GetPoiGeneralDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiGeneralDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface GetPoiOfficeDto
 */
export interface GetPoiOfficeDto {
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof GetPoiOfficeDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDto
     */
    facultyId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof GetPoiOfficeDto
     */
    room?: RoomRes;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetPoiOfficeDto
     */
    secretaries?: Array<IdWrapper>;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDto
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetPoiOfficeDtoReq
 */
export interface GetPoiOfficeDtoReq {
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoReq
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof GetPoiOfficeDtoReq
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDtoReq
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoReq
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoReq
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoReq
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof GetPoiOfficeDtoReq
     */
    room?: RoomReq;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetPoiOfficeDtoReq
     */
    secretaries?: Array<IdWrapper>;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDtoReq
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetPoiOfficeDtoRes
 */
export interface GetPoiOfficeDtoRes {
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoRes
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof GetPoiOfficeDtoRes
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoRes
     */
    facultyId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDtoRes
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoRes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoRes
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiOfficeDtoRes
     */
    phone?: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof GetPoiOfficeDtoRes
     */
    room?: RoomRes;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof GetPoiOfficeDtoRes
     */
    secretaries?: Array<IdWrapper>;
    /**
     * 
     * @type {number}
     * @memberof GetPoiOfficeDtoRes
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetPoiStudentUnionDto
 */
export interface GetPoiStudentUnionDto {
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof GetPoiStudentUnionDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    facultyId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetPoiStudentUnionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof GetPoiStudentUnionDto
     */
    room?: RoomRes;
    /**
     * 
     * @type {number}
     * @memberof GetPoiStudentUnionDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPoiStudentUnionDto
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface GetRoomDto
 */
export interface GetRoomDto {
    /**
     * 
     * @type {IdWrapper}
     * @memberof GetRoomDto
     */
    building?: IdWrapper;
    /**
     * 
     * @type {boolean}
     * @memberof GetRoomDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetRoomDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRoomDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRoomDto
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRoomDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface GetSemesterDto
 */
export interface GetSemesterDto {
    /**
     * 
     * @type {Course}
     * @memberof GetSemesterDto
     */
    course?: Course;
    /**
     * 
     * @type {string}
     * @memberof GetSemesterDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSemesterDto
     */
    iCalFileHttpLink?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSemesterDto
     */
    iCalHash?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSemesterDto
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSemesterDto
     */
    isValidICal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSemesterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSemesterDto
     */
    semesterNumber?: number;
}
/**
 * 
 * @export
 * @interface GitProperties
 */
export interface GitProperties {
    /**
     * 
     * @type {string}
     * @memberof GitProperties
     */
    branch?: string;
    /**
     * 
     * @type {string}
     * @memberof GitProperties
     */
    commitId?: string;
    /**
     * 
     * @type {string}
     * @memberof GitProperties
     */
    commitTime?: string;
    /**
     * 
     * @type {string}
     * @memberof GitProperties
     */
    shortCommitId?: string;
}
/**
 * 
 * @export
 * @interface HKAUser
 */
export interface HKAUser {
    /**
     * 
     * @type {number}
     * @memberof HKAUser
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof HKAUser
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HKAUser
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface IdWrapper
 */
export interface IdWrapper {
    /**
     * 
     * @type {number}
     * @memberof IdWrapper
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface IgnoredCourseDto
 */
export interface IgnoredCourseDto {
    /**
     * 
     * @type {number}
     * @memberof IgnoredCourseDto
     */
    lsfId?: number;
    /**
     * 
     * @type {string}
     * @memberof IgnoredCourseDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface JWTToken
 */
export interface JWTToken {
    /**
     * 
     * @type {string}
     * @memberof JWTToken
     */
    id_token?: string;
}
/**
 * 
 * @export
 * @interface JWTUserDto
 */
export interface JWTUserDto {
    /**
     * 
     * @type {string}
     * @memberof JWTUserDto
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof JWTUserDto
     */
    rememberMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JWTUserDto
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface LdapFaculty
 */
export interface LdapFaculty {
    /**
     * 
     * @type {string}
     * @memberof LdapFaculty
     */
    alias?: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFaculty
     */
    courses?: Array<IdWrapper>;
    /**
     * 
     * @type {boolean}
     * @memberof LdapFaculty
     */
    faculty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LdapFaculty
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LdapFaculty
     */
    name: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFaculty
     */
    newsCategories?: Array<IdWrapper>;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFaculty
     */
    poiCourses?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface LdapFacultyDto
 */
export interface LdapFacultyDto {
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyDto
     */
    alias?: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyDto
     */
    courses?: Array<IdWrapper>;
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyDto
     */
    id?: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyDto
     */
    newsCategories?: Array<IdWrapper>;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyDto
     */
    poiCourses?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface LdapFacultyReq
 */
export interface LdapFacultyReq {
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyReq
     */
    alias?: string;
    /**
     * 
     * @type {Array<Course>}
     * @memberof LdapFacultyReq
     */
    courses?: Array<Course>;
    /**
     * 
     * @type {boolean}
     * @memberof LdapFacultyReq
     */
    faculty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyReq
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyReq
     */
    name: string;
    /**
     * 
     * @type {Array<NewsCategory>}
     * @memberof LdapFacultyReq
     */
    newsCategories?: Array<NewsCategory>;
    /**
     * 
     * @type {Array<PointOfInterestCourseReq>}
     * @memberof LdapFacultyReq
     */
    poiCourses?: Array<PointOfInterestCourseReq>;
}
/**
 * 
 * @export
 * @interface LdapFacultyRes
 */
export interface LdapFacultyRes {
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyRes
     */
    alias?: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyRes
     */
    courses?: Array<IdWrapper>;
    /**
     * 
     * @type {boolean}
     * @memberof LdapFacultyRes
     */
    faculty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyRes
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LdapFacultyRes
     */
    name: string;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyRes
     */
    newsCategories?: Array<IdWrapper>;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof LdapFacultyRes
     */
    poiCourses?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface NewsCategory
 */
export interface NewsCategory {
    /**
     * 
     * @type {LdapFacultyReq}
     * @memberof NewsCategory
     */
    faculty?: LdapFacultyReq;
    /**
     * 
     * @type {string}
     * @memberof NewsCategory
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCategory
     */
    format?: NewsCategoryFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof NewsCategory
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewsCategory
     */
    ignoreUpdatedAt?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsCategory
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCategory
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCategory
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NewsCategoryFormatEnum {
    Json = 'JSON',
    Rss = 'RSS'
}

/**
 * 
 * @export
 * @interface PageGetBuildingDto
 */
export interface PageGetBuildingDto {
    /**
     * 
     * @type {Array<GetBuildingDto>}
     * @memberof PageGetBuildingDto
     */
    content?: Array<GetBuildingDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetBuildingDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetBuildingDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetBuildingDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageGetBuildingDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGetBuildingDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageGetBuildingDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageGetBuildingDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageGetBuildingDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageGetBuildingDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGetBuildingDto
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageGetPersonDto
 */
export interface PageGetPersonDto {
    /**
     * 
     * @type {Array<GetPersonDto>}
     * @memberof PageGetPersonDto
     */
    content?: Array<GetPersonDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetPersonDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetPersonDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGetPersonDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageGetPersonDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGetPersonDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageGetPersonDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageGetPersonDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageGetPersonDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageGetPersonDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGetPersonDto
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagePointOfInterestCourse
 */
export interface PagePointOfInterestCourse {
    /**
     * 
     * @type {Array<PointOfInterestCourseRes>}
     * @memberof PagePointOfInterestCourse
     */
    content?: Array<PointOfInterestCourseRes>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestCourse
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestCourse
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestCourse
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestCourse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestCourse
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePointOfInterestCourse
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestCourse
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePointOfInterestCourse
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestCourse
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestCourse
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagePointOfInterestGeneral
 */
export interface PagePointOfInterestGeneral {
    /**
     * 
     * @type {Array<PointOfInterestGeneral>}
     * @memberof PagePointOfInterestGeneral
     */
    content?: Array<PointOfInterestGeneral>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestGeneral
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestGeneral
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestGeneral
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestGeneral
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestGeneral
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePointOfInterestGeneral
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestGeneral
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePointOfInterestGeneral
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestGeneral
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestGeneral
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagePointOfInterestOffice
 */
export interface PagePointOfInterestOffice {
    /**
     * 
     * @type {Array<PointOfInterestOffice>}
     * @memberof PagePointOfInterestOffice
     */
    content?: Array<PointOfInterestOffice>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestOffice
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestOffice
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestOffice
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestOffice
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestOffice
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePointOfInterestOffice
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestOffice
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePointOfInterestOffice
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestOffice
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestOffice
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagePointOfInterestStudentUnion
 */
export interface PagePointOfInterestStudentUnion {
    /**
     * 
     * @type {Array<PointOfInterestStudentUnion>}
     * @memberof PagePointOfInterestStudentUnion
     */
    content?: Array<PointOfInterestStudentUnion>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestStudentUnion
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestStudentUnion
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePointOfInterestStudentUnion
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestStudentUnion
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestStudentUnion
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePointOfInterestStudentUnion
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestStudentUnion
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePointOfInterestStudentUnion
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestStudentUnion
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePointOfInterestStudentUnion
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageRoom
 */
export interface PageRoom {
    /**
     * 
     * @type {Array<Room>}
     * @memberof PageRoom
     */
    content?: Array<Room>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoom
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoom
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRoom
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRoom
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRoom
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageRoom
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageRoom
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRoom
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRoom
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRoom
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordChangeDto
 */
export interface PasswordChangeDto {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeDto
     */
    newPassword1?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeDto
     */
    newPassword2?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeDto
     */
    oldPassword?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestCourseReq
 */
export interface PointOfInterestCourseReq {
    /**
     * 
     * @type {Building}
     * @memberof PointOfInterestCourseReq
     */
    building?: Building;
    /**
     * 
     * @type {LdapFacultyReq}
     * @memberof PointOfInterestCourseReq
     */
    faculty?: LdapFacultyReq;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestCourseReq
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestCourseReq
     */
    name: string;
    /**
     * 
     * @type {PointOfInterestOfficeReq}
     * @memberof PointOfInterestCourseReq
     */
    office?: PointOfInterestOfficeReq;
    /**
     * 
     * @type {PointOfInterestStudentUnionReq}
     * @memberof PointOfInterestCourseReq
     */
    studentUnion?: PointOfInterestStudentUnionReq;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestCourseReq
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestCourseRes
 */
export interface PointOfInterestCourseRes {
    /**
     * 
     * @type {Building}
     * @memberof PointOfInterestCourseRes
     */
    building?: Building;
    /**
     * 
     * @type {LdapFacultyRes}
     * @memberof PointOfInterestCourseRes
     */
    faculty?: LdapFacultyRes;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestCourseRes
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestCourseRes
     */
    name: string;
    /**
     * 
     * @type {PointOfInterestOfficeRes}
     * @memberof PointOfInterestCourseRes
     */
    office?: PointOfInterestOfficeRes;
    /**
     * 
     * @type {PointOfInterestStudentUnionRes}
     * @memberof PointOfInterestCourseRes
     */
    studentUnion?: PointOfInterestStudentUnionRes;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestCourseRes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestGeneral
 */
export interface PointOfInterestGeneral {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    email?: string;
    /**
     * 
     * @type {LdapFaculty}
     * @memberof PointOfInterestGeneral
     */
    faculty?: LdapFaculty;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestGeneral
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    phone?: string;
    /**
     * 
     * @type {Room}
     * @memberof PointOfInterestGeneral
     */
    room?: Room;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestGeneral
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestOffice
 */
export interface PointOfInterestOffice {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOffice
     */
    email: string;
    /**
     * 
     * @type {LdapFaculty}
     * @memberof PointOfInterestOffice
     */
    faculty?: LdapFaculty;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestOffice
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOffice
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOffice
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOffice
     */
    phone: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof PointOfInterestOffice
     */
    room?: RoomRes;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof PointOfInterestOffice
     */
    secretaries?: Array<IdWrapper>;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOffice
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestOfficeReq
 */
export interface PointOfInterestOfficeReq {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeReq
     */
    email: string;
    /**
     * 
     * @type {LdapFacultyReq}
     * @memberof PointOfInterestOfficeReq
     */
    faculty?: LdapFacultyReq;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestOfficeReq
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeReq
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeReq
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeReq
     */
    phone: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PointOfInterestOfficeReq
     */
    room?: RoomReq;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeReq
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestOfficeRes
 */
export interface PointOfInterestOfficeRes {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeRes
     */
    email: string;
    /**
     * 
     * @type {LdapFacultyRes}
     * @memberof PointOfInterestOfficeRes
     */
    faculty?: LdapFacultyRes;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestOfficeRes
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeRes
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeRes
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeRes
     */
    phone: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof PointOfInterestOfficeRes
     */
    room?: RoomRes;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof PointOfInterestOfficeRes
     */
    secretaries?: Array<IdWrapper>;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestOfficeRes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestStudentUnion
 */
export interface PointOfInterestStudentUnion {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    email: string;
    /**
     * 
     * @type {LdapFaculty}
     * @memberof PointOfInterestStudentUnion
     */
    faculty?: LdapFaculty;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestStudentUnion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    phone?: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof PointOfInterestStudentUnion
     */
    room?: RoomRes;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnion
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestStudentUnionReq
 */
export interface PointOfInterestStudentUnionReq {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    email: string;
    /**
     * 
     * @type {LdapFacultyReq}
     * @memberof PointOfInterestStudentUnionReq
     */
    faculty?: LdapFacultyReq;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestStudentUnionReq
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PointOfInterestStudentUnionReq
     */
    room?: RoomReq;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionReq
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface PointOfInterestStudentUnionRes
 */
export interface PointOfInterestStudentUnionRes {
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    email: string;
    /**
     * 
     * @type {LdapFacultyRes}
     * @memberof PointOfInterestStudentUnionRes
     */
    faculty?: LdapFacultyRes;
    /**
     * 
     * @type {number}
     * @memberof PointOfInterestStudentUnionRes
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    phone?: string;
    /**
     * 
     * @type {RoomRes}
     * @memberof PointOfInterestStudentUnionRes
     */
    room?: RoomRes;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PointOfInterestStudentUnionRes
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface PostBuildingDto
 */
export interface PostBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof PostBuildingDto
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof PostBuildingDto
     */
    coordinatesCenter?: string;
    /**
     * 
     * @type {string}
     * @memberof PostBuildingDto
     */
    houseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PostBuildingDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PostBuildingDto
     */
    postcode?: number;
    /**
     * 
     * @type {string}
     * @memberof PostBuildingDto
     */
    street?: string;
}
/**
 * 
 * @export
 * @interface PostChangeRequestDto
 */
export interface PostChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PostChangeRequestDto
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostChangeRequestDto
     */
    hasImage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostChangeRequestDto
     */
    hsUser?: string;
    /**
     * 
     * @type {number}
     * @memberof PostChangeRequestDto
     */
    objectId?: number;
    /**
     * 
     * @type {string}
     * @memberof PostChangeRequestDto
     */
    type?: PostChangeRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostChangeRequestDto
     */
    validationImage?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostChangeRequestDtoTypeEnum {
    Course = 'COURSE',
    General = 'GENERAL',
    Learningplace = 'LEARNINGPLACE',
    Office = 'OFFICE',
    Person = 'PERSON',
    Studentunion = 'STUDENTUNION'
}

/**
 * 
 * @export
 * @interface PostConfigurationDto
 */
export interface PostConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof PostConfigurationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostConfigurationDto
     */
    property: string;
}
/**
 * 
 * @export
 * @interface PostCourseDto
 */
export interface PostCourseDto {
    /**
     * 
     * @type {string}
     * @memberof PostCourseDto
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCourseDto
     */
    name?: string;
    /**
     * 
     * @type {Array<PostSemesterDto>}
     * @memberof PostCourseDto
     */
    semesters?: Array<PostSemesterDto>;
}
/**
 * 
 * @export
 * @interface PostNewsCategoryDto
 */
export interface PostNewsCategoryDto {
    /**
     * 
     * @type {LdapFacultyDto}
     * @memberof PostNewsCategoryDto
     */
    faculty?: LdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    format?: PostNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNewsCategoryDto
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostNewsCategoryDtoFormatEnum {
    Json = 'JSON',
    Rss = 'RSS'
}

/**
 * 
 * @export
 * @interface PostPersonDto
 */
export interface PostPersonDto {
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    academicDegree?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    consultationHour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostPersonDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    faculty?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostPersonDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    ldap?: string;
    /**
     * 
     * @type {PostPoiOfficeDto}
     * @memberof PostPersonDto
     */
    office?: PostPoiOfficeDto;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    remark?: string;
    /**
     * 
     * @type {IdWrapper}
     * @memberof PostPersonDto
     */
    room?: IdWrapper;
    /**
     * 
     * @type {string}
     * @memberof PostPersonDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof PostPersonDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface PostPoiCourseDto
 */
export interface PostPoiCourseDto {
    /**
     * 
     * @type {GetBuildingDto}
     * @memberof PostPoiCourseDto
     */
    building?: GetBuildingDto;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PostPoiCourseDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PostPoiCourseDto
     */
    name?: string;
    /**
     * 
     * @type {GetPoiOfficeDtoReq}
     * @memberof PostPoiCourseDto
     */
    office?: GetPoiOfficeDtoReq;
    /**
     * 
     * @type {PointOfInterestStudentUnionReq}
     * @memberof PostPoiCourseDto
     */
    studentUnion?: PointOfInterestStudentUnionReq;
}
/**
 * 
 * @export
 * @interface PostPoiGeneralDto
 */
export interface PostPoiGeneralDto {
    /**
     * 
     * @type {string}
     * @memberof PostPoiGeneralDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PostPoiGeneralDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PostPoiGeneralDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiGeneralDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiGeneralDto
     */
    phone?: string;
    /**
     * 
     * @type {GetRoomDto}
     * @memberof PostPoiGeneralDto
     */
    room?: GetRoomDto;
    /**
     * 
     * @type {string}
     * @memberof PostPoiGeneralDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface PostPoiOfficeDto
 */
export interface PostPoiOfficeDto {
    /**
     * 
     * @type {string}
     * @memberof PostPoiOfficeDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PostPoiOfficeDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PostPoiOfficeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiOfficeDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiOfficeDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PostPoiOfficeDto
     */
    room?: RoomReq;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof PostPoiOfficeDto
     */
    secretaries?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface PostPoiStudentUnionDto
 */
export interface PostPoiStudentUnionDto {
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PostPoiStudentUnionDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PostPoiStudentUnionDto
     */
    room?: RoomReq;
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPoiStudentUnionDto
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface PostRoomDto
 */
export interface PostRoomDto {
    /**
     * 
     * @type {Building}
     * @memberof PostRoomDto
     */
    building?: Building;
    /**
     * 
     * @type {boolean}
     * @memberof PostRoomDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostRoomDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PostRoomDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface PostSemesterDto
 */
export interface PostSemesterDto {
    /**
     * 
     * @type {string}
     * @memberof PostSemesterDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSemesterDto
     */
    iCalFileHttpLink?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSemesterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PostSemesterDto
     */
    semesterNumber?: number;
}
/**
 * 
 * @export
 * @interface PutBuildingDto
 */
export interface PutBuildingDto {
    /**
     * 
     * @type {string}
     * @memberof PutBuildingDto
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof PutBuildingDto
     */
    coordinatesCenter?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutBuildingDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutBuildingDto
     */
    houseNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof PutBuildingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutBuildingDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PutBuildingDto
     */
    postcode?: number;
    /**
     * 
     * @type {string}
     * @memberof PutBuildingDto
     */
    street?: string;
}
/**
 * 
 * @export
 * @interface PutConfigurationDto
 */
export interface PutConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof PutConfigurationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutConfigurationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PutConfigurationDto
     */
    property: string;
}
/**
 * 
 * @export
 * @interface PutCourseDto
 */
export interface PutCourseDto {
    /**
     * 
     * @type {string}
     * @memberof PutCourseDto
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof PutCourseDto
     */
    name?: string;
    /**
     * 
     * @type {Array<PutSemesterDto>}
     * @memberof PutCourseDto
     */
    semesters?: Array<PutSemesterDto>;
}
/**
 * 
 * @export
 * @interface PutNewsCategoryDto
 */
export interface PutNewsCategoryDto {
    /**
     * 
     * @type {LdapFacultyDto}
     * @memberof PutNewsCategoryDto
     */
    faculty?: LdapFacultyDto;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    feed?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    format?: PutNewsCategoryDtoFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof PutNewsCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewsCategoryDto
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PutNewsCategoryDtoFormatEnum {
    Json = 'JSON',
    Rss = 'RSS'
}

/**
 * 
 * @export
 * @interface PutPersonDto
 */
export interface PutPersonDto {
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    academicDegree?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    consultationHour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutPersonDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    faculty?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof PutPersonDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutPersonDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    ldap?: string;
    /**
     * 
     * @type {PutPoiOfficeDto}
     * @memberof PutPersonDto
     */
    office?: PutPoiOfficeDto;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    remark?: string;
    /**
     * 
     * @type {PutRoomDto}
     * @memberof PutPersonDto
     */
    room?: PutRoomDto;
    /**
     * 
     * @type {string}
     * @memberof PutPersonDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof PutPersonDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface PutPoiCourseDto
 */
export interface PutPoiCourseDto {
    /**
     * 
     * @type {GetBuildingDto}
     * @memberof PutPoiCourseDto
     */
    building?: GetBuildingDto;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PutPoiCourseDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof PutPoiCourseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutPoiCourseDto
     */
    name?: string;
    /**
     * 
     * @type {GetPoiOfficeDtoReq}
     * @memberof PutPoiCourseDto
     */
    office?: GetPoiOfficeDtoReq;
    /**
     * 
     * @type {PointOfInterestStudentUnionReq}
     * @memberof PutPoiCourseDto
     */
    studentUnion?: PointOfInterestStudentUnionReq;
}
/**
 * 
 * @export
 * @interface PutPoiGeneralDto
 */
export interface PutPoiGeneralDto {
    /**
     * 
     * @type {string}
     * @memberof PutPoiGeneralDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PutPoiGeneralDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof PutPoiGeneralDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutPoiGeneralDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiGeneralDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiGeneralDto
     */
    phone?: string;
    /**
     * 
     * @type {GetRoomDto}
     * @memberof PutPoiGeneralDto
     */
    room?: GetRoomDto;
    /**
     * 
     * @type {string}
     * @memberof PutPoiGeneralDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface PutPoiOfficeDto
 */
export interface PutPoiOfficeDto {
    /**
     * 
     * @type {string}
     * @memberof PutPoiOfficeDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PutPoiOfficeDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof PutPoiOfficeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutPoiOfficeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiOfficeDto
     */
    openingHours?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiOfficeDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PutPoiOfficeDto
     */
    room?: RoomReq;
    /**
     * 
     * @type {Array<IdWrapper>}
     * @memberof PutPoiOfficeDto
     */
    secretaries?: Array<IdWrapper>;
}
/**
 * 
 * @export
 * @interface PutPoiStudentUnionDto
 */
export interface PutPoiStudentUnionDto {
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    email?: string;
    /**
     * 
     * @type {GetLdapFacultyDto}
     * @memberof PutPoiStudentUnionDto
     */
    faculty?: GetLdapFacultyDto;
    /**
     * 
     * @type {number}
     * @memberof PutPoiStudentUnionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    meeting?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    phone?: string;
    /**
     * 
     * @type {RoomReq}
     * @memberof PutPoiStudentUnionDto
     */
    room?: RoomReq;
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PutPoiStudentUnionDto
     */
    urlTwo?: string;
}
/**
 * 
 * @export
 * @interface PutRoomDto
 */
export interface PutRoomDto {
    /**
     * 
     * @type {Building}
     * @memberof PutRoomDto
     */
    building?: Building;
    /**
     * 
     * @type {boolean}
     * @memberof PutRoomDto
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutRoomDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PutRoomDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PutRoomDto
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface PutSemesterDto
 */
export interface PutSemesterDto {
    /**
     * 
     * @type {string}
     * @memberof PutSemesterDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PutSemesterDto
     */
    iCalFileHttpLink?: string;
    /**
     * 
     * @type {string}
     * @memberof PutSemesterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PutSemesterDto
     */
    semesterNumber?: number;
}
/**
 * 
 * @export
 * @interface ResponseEntity
 */
export interface ResponseEntity {
    /**
     * 
     * @type {object}
     * @memberof ResponseEntity
     */
    body?: object;
    /**
     * 
     * @type {string}
     * @memberof ResponseEntity
     */
    statusCode?: ResponseEntityStatusCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseEntity
     */
    statusCodeValue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ResponseEntityStatusCodeEnum {
    Accepted = 'ACCEPTED',
    AlreadyReported = 'ALREADY_REPORTED',
    BadGateway = 'BAD_GATEWAY',
    BadRequest = 'BAD_REQUEST',
    BandwidthLimitExceeded = 'BANDWIDTH_LIMIT_EXCEEDED',
    Checkpoint = 'CHECKPOINT',
    Conflict = 'CONFLICT',
    Continue = 'CONTINUE',
    Created = 'CREATED',
    DestinationLocked = 'DESTINATION_LOCKED',
    ExpectationFailed = 'EXPECTATION_FAILED',
    FailedDependency = 'FAILED_DEPENDENCY',
    Forbidden = 'FORBIDDEN',
    Found = 'FOUND',
    GatewayTimeout = 'GATEWAY_TIMEOUT',
    Gone = 'GONE',
    HttpVersionNotSupported = 'HTTP_VERSION_NOT_SUPPORTED',
    ImUsed = 'IM_USED',
    InsufficientSpaceOnResource = 'INSUFFICIENT_SPACE_ON_RESOURCE',
    InsufficientStorage = 'INSUFFICIENT_STORAGE',
    InternalServerError = 'INTERNAL_SERVER_ERROR',
    IAmATeapot = 'I_AM_A_TEAPOT',
    LengthRequired = 'LENGTH_REQUIRED',
    Locked = 'LOCKED',
    LoopDetected = 'LOOP_DETECTED',
    MethodFailure = 'METHOD_FAILURE',
    MethodNotAllowed = 'METHOD_NOT_ALLOWED',
    MovedPermanently = 'MOVED_PERMANENTLY',
    MovedTemporarily = 'MOVED_TEMPORARILY',
    MultipleChoices = 'MULTIPLE_CHOICES',
    MultiStatus = 'MULTI_STATUS',
    NetworkAuthenticationRequired = 'NETWORK_AUTHENTICATION_REQUIRED',
    NonAuthoritativeInformation = 'NON_AUTHORITATIVE_INFORMATION',
    NotAcceptable = 'NOT_ACCEPTABLE',
    NotExtended = 'NOT_EXTENDED',
    NotFound = 'NOT_FOUND',
    NotImplemented = 'NOT_IMPLEMENTED',
    NotModified = 'NOT_MODIFIED',
    NoContent = 'NO_CONTENT',
    Ok = 'OK',
    PartialContent = 'PARTIAL_CONTENT',
    PayloadTooLarge = 'PAYLOAD_TOO_LARGE',
    PaymentRequired = 'PAYMENT_REQUIRED',
    PermanentRedirect = 'PERMANENT_REDIRECT',
    PreconditionFailed = 'PRECONDITION_FAILED',
    PreconditionRequired = 'PRECONDITION_REQUIRED',
    Processing = 'PROCESSING',
    ProxyAuthenticationRequired = 'PROXY_AUTHENTICATION_REQUIRED',
    RequestedRangeNotSatisfiable = 'REQUESTED_RANGE_NOT_SATISFIABLE',
    RequestEntityTooLarge = 'REQUEST_ENTITY_TOO_LARGE',
    RequestHeaderFieldsTooLarge = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    RequestTimeout = 'REQUEST_TIMEOUT',
    RequestUriTooLong = 'REQUEST_URI_TOO_LONG',
    ResetContent = 'RESET_CONTENT',
    SeeOther = 'SEE_OTHER',
    ServiceUnavailable = 'SERVICE_UNAVAILABLE',
    SwitchingProtocols = 'SWITCHING_PROTOCOLS',
    TemporaryRedirect = 'TEMPORARY_REDIRECT',
    TooEarly = 'TOO_EARLY',
    TooManyRequests = 'TOO_MANY_REQUESTS',
    Unauthorized = 'UNAUTHORIZED',
    UnavailableForLegalReasons = 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UnprocessableEntity = 'UNPROCESSABLE_ENTITY',
    UnsupportedMediaType = 'UNSUPPORTED_MEDIA_TYPE',
    UpgradeRequired = 'UPGRADE_REQUIRED',
    UriTooLong = 'URI_TOO_LONG',
    UseProxy = 'USE_PROXY',
    VariantAlsoNegotiates = 'VARIANT_ALSO_NEGOTIATES'
}

/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {IdWrapper}
     * @memberof Room
     */
    building?: IdWrapper;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Room
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface RoomReq
 */
export interface RoomReq {
    /**
     * 
     * @type {Building}
     * @memberof RoomReq
     */
    building?: Building;
    /**
     * 
     * @type {number}
     * @memberof RoomReq
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomReq
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomReq
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoomReq
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface RoomRes
 */
export interface RoomRes {
    /**
     * 
     * @type {IdWrapper}
     * @memberof RoomRes
     */
    building?: IdWrapper;
    /**
     * 
     * @type {number}
     * @memberof RoomRes
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomRes
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomRes
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRes
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface SettingsDto
 */
export interface SettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    gradesInterval?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    dataAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    username?: string;
}

/**
 * AuthenticationV1Api - axios parameter creator
 * @export
 */
export const AuthenticationV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authenticate to gain a JWT
         * @param {JWTUserDto} jwtUserDto jwtUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate1: async (jwtUserDto: JWTUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtUserDto' is not null or undefined
            assertParamExists('authenticate1', 'jwtUserDto', jwtUserDto)
            const localVarPath = `/api/authenticate/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jwtUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationV1Api - functional programming interface
 * @export
 */
export const AuthenticationV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary authenticate to gain a JWT
         * @param {JWTUserDto} jwtUserDto jwtUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate1(jwtUserDto: JWTUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate1(jwtUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationV1Api - factory interface
 * @export
 */
export const AuthenticationV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary authenticate to gain a JWT
         * @param {JWTUserDto} jwtUserDto jwtUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate1(jwtUserDto: JWTUserDto, options?: any): AxiosPromise<ResponseEntity> {
            return localVarFp.authenticate1(jwtUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationV1Api - object-oriented interface
 * @export
 * @class AuthenticationV1Api
 * @extends {BaseAPI}
 */
export class AuthenticationV1Api extends BaseAPI {
    /**
     * 
     * @summary authenticate to gain a JWT
     * @param {JWTUserDto} jwtUserDto jwtUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationV1Api
     */
    public authenticate1(jwtUserDto: JWTUserDto, options?: any) {
        return AuthenticationV1ApiFp(this.configuration).authenticate1(jwtUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingV1Api - axios parameter creator
 * @export
 */
export const BuildingV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new building
         * @param {PostBuildingDto} buildingToCreate buildingToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding1: async (buildingToCreate: PostBuildingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingToCreate' is not null or undefined
            assertParamExists('createBuilding1', 'buildingToCreate', buildingToCreate)
            const localVarPath = `/api/buildings/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingToCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a building
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBuilding', 'id', id)
            const localVarPath = `/api/buildings/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildings1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/buildings/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a building for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuilding: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBuilding', 'id', id)
            const localVarPath = `/api/buildings/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of available buildings
         * @param {number} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingsPage1: async (id?: number, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/buildings/v1/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a building
         * @param {number} id id
         * @param {PutBuildingDto} buildingToUpdate buildingToUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding: async (id: number, buildingToUpdate: PutBuildingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBuilding', 'id', id)
            // verify required parameter 'buildingToUpdate' is not null or undefined
            assertParamExists('updateBuilding', 'buildingToUpdate', buildingToUpdate)
            const localVarPath = `/api/buildings/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingToUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingV1Api - functional programming interface
 * @export
 */
export const BuildingV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new building
         * @param {PostBuildingDto} buildingToCreate buildingToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBuilding1(buildingToCreate: PostBuildingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBuilding1(buildingToCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a building
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBuilding(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBuilding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBuildings1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetBuildingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBuildings1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a building for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuilding(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuilding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of available buildings
         * @param {number} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuildingsPage1(id?: number, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGetBuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuildingsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a building
         * @param {number} id id
         * @param {PutBuildingDto} buildingToUpdate buildingToUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBuilding(id: number, buildingToUpdate: PutBuildingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBuilding(id, buildingToUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingV1Api - factory interface
 * @export
 */
export const BuildingV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new building
         * @param {PostBuildingDto} buildingToCreate buildingToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuilding1(buildingToCreate: PostBuildingDto, options?: any): AxiosPromise<GetBuildingDto> {
            return localVarFp.createBuilding1(buildingToCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a building
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBuilding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available buildings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildings1(options?: any): AxiosPromise<Array<GetBuildingDto>> {
            return localVarFp.getAllBuildings1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a building for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuilding(id: number, options?: any): AxiosPromise<GetBuildingDto> {
            return localVarFp.getBuilding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of available buildings
         * @param {number} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingsPage1(id?: number, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PageGetBuildingDto> {
            return localVarFp.getBuildingsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a building
         * @param {number} id id
         * @param {PutBuildingDto} buildingToUpdate buildingToUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuilding(id: number, buildingToUpdate: PutBuildingDto, options?: any): AxiosPromise<GetBuildingDto> {
            return localVarFp.updateBuilding(id, buildingToUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingV1Api - object-oriented interface
 * @export
 * @class BuildingV1Api
 * @extends {BaseAPI}
 */
export class BuildingV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new building
     * @param {PostBuildingDto} buildingToCreate buildingToCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public createBuilding1(buildingToCreate: PostBuildingDto, options?: any) {
        return BuildingV1ApiFp(this.configuration).createBuilding1(buildingToCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a building
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public deleteBuilding(id: number, options?: any) {
        return BuildingV1ApiFp(this.configuration).deleteBuilding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available buildings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public getAllBuildings1(options?: any) {
        return BuildingV1ApiFp(this.configuration).getAllBuildings1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a building for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public getBuilding(id: number, options?: any) {
        return BuildingV1ApiFp(this.configuration).getBuilding(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of available buildings
     * @param {number} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public getBuildingsPage1(id?: number, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return BuildingV1ApiFp(this.configuration).getBuildingsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a building
     * @param {number} id id
     * @param {PutBuildingDto} buildingToUpdate buildingToUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingV1Api
     */
    public updateBuilding(id: number, buildingToUpdate: PutBuildingDto, options?: any) {
        return BuildingV1ApiFp(this.configuration).updateBuilding(id, buildingToUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarV1Api - axios parameter creator
 * @export
 */
export const CalendarV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarErstiTasksCurrent1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/ersti/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of endpoints to ical files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarList1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarScheduleCurrent1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/schedule/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarScheduleNext1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/schedule/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduleCurrent1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/schedule/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduleNext1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/schedule/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskPlan1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calendar/v1/ersti/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarV1Api - functional programming interface
 * @export
 */
export const CalendarV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarErstiTasksCurrent1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarErstiTasksCurrent1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of endpoints to ical files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarList1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarList1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarScheduleCurrent1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarScheduleCurrent1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarScheduleNext1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarScheduleNext1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putScheduleCurrent1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putScheduleCurrent1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putScheduleNext1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putScheduleNext1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTaskPlan1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTaskPlan1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarV1Api - factory interface
 * @export
 */
export const CalendarV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarErstiTasksCurrent1(options?: any): AxiosPromise<string> {
            return localVarFp.getCalendarErstiTasksCurrent1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of endpoints to ical files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarList1(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCalendarList1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarScheduleCurrent1(options?: any): AxiosPromise<string> {
            return localVarFp.getCalendarScheduleCurrent1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarScheduleNext1(options?: any): AxiosPromise<string> {
            return localVarFp.getCalendarScheduleNext1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update ical file of current semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduleCurrent1(options?: any): AxiosPromise<void> {
            return localVarFp.putScheduleCurrent1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update ical file of next semester schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduleNext1(options?: any): AxiosPromise<void> {
            return localVarFp.putScheduleNext1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update ical file of taskPlan in current semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskPlan1(options?: any): AxiosPromise<void> {
            return localVarFp.putTaskPlan1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarV1Api - object-oriented interface
 * @export
 * @class CalendarV1Api
 * @extends {BaseAPI}
 */
export class CalendarV1Api extends BaseAPI {
    /**
     * 
     * @summary ical file of taskPlan in current semester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public getCalendarErstiTasksCurrent1(options?: any) {
        return CalendarV1ApiFp(this.configuration).getCalendarErstiTasksCurrent1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of endpoints to ical files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public getCalendarList1(options?: any) {
        return CalendarV1ApiFp(this.configuration).getCalendarList1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ical file of current semester schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public getCalendarScheduleCurrent1(options?: any) {
        return CalendarV1ApiFp(this.configuration).getCalendarScheduleCurrent1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ical file of next semester schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public getCalendarScheduleNext1(options?: any) {
        return CalendarV1ApiFp(this.configuration).getCalendarScheduleNext1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update ical file of current semester schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public putScheduleCurrent1(options?: any) {
        return CalendarV1ApiFp(this.configuration).putScheduleCurrent1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update ical file of next semester schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public putScheduleNext1(options?: any) {
        return CalendarV1ApiFp(this.configuration).putScheduleNext1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update ical file of taskPlan in current semester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarV1Api
     */
    public putTaskPlan1(options?: any) {
        return CalendarV1ApiFp(this.configuration).putTaskPlan1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChangeRequestsV1Api - axios parameter creator
 * @export
 */
export const ChangeRequestsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest: async (changeRequestToCreate: PostChangeRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestToCreate' is not null or undefined
            assertParamExists('createChangeRequest', 'changeRequestToCreate', changeRequestToCreate)
            const localVarPath = `/api/changerequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeRequestToCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest1: async (changeRequestToCreate: PostChangeRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestToCreate' is not null or undefined
            assertParamExists('createChangeRequest1', 'changeRequestToCreate', changeRequestToCreate)
            const localVarPath = `/api/changerequests/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeRequestToCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a changeRequest
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChangeRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChangeRequest', 'id', id)
            const localVarPath = `/api/changerequests/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChangeRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/changerequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChangeRequests1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/changerequests/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a changeRequest for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChangeRequest', 'id', id)
            const localVarPath = `/api/changerequests/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a validation image for a changeRequest with given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestValidationImage1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChangeRequestValidationImage1', 'id', id)
            const localVarPath = `/api/changerequests/v1/image/{id}.png`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all changeRequests regarding an object (id) of a given type
         * @param {number} objectId objectId
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsByObject1: async (objectId: number, type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getChangeRequestsByObject1', 'objectId', objectId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getChangeRequestsByObject1', 'type', type)
            const localVarPath = `/api/changerequests/v1/object/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all changeRequests regarding objects of a given type
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsByType1: async (type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getChangeRequestsByType1', 'type', type)
            const localVarPath = `/api/changerequests/v1/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeRequestsV1Api - functional programming interface
 * @export
 */
export const ChangeRequestsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeRequestsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChangeRequest(changeRequestToCreate: PostChangeRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChangeRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChangeRequest(changeRequestToCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChangeRequest1(changeRequestToCreate: PostChangeRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChangeRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChangeRequest1(changeRequestToCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a changeRequest
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChangeRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChangeRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllChangeRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChangeRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllChangeRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllChangeRequests1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChangeRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllChangeRequests1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a changeRequest for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChangeRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a validation image for a changeRequest with given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequestValidationImage1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequestValidationImage1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all changeRequests regarding an object (id) of a given type
         * @param {number} objectId objectId
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequestsByObject1(objectId: number, type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChangeRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequestsByObject1(objectId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all changeRequests regarding objects of a given type
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequestsByType1(type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetChangeRequestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequestsByType1(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChangeRequestsV1Api - factory interface
 * @export
 */
export const ChangeRequestsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeRequestsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest(changeRequestToCreate: PostChangeRequestDto, options?: any): AxiosPromise<GetChangeRequestDto> {
            return localVarFp.createChangeRequest(changeRequestToCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new changeRequest
         * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequest1(changeRequestToCreate: PostChangeRequestDto, options?: any): AxiosPromise<GetChangeRequestDto> {
            return localVarFp.createChangeRequest1(changeRequestToCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a changeRequest
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChangeRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteChangeRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChangeRequests(options?: any): AxiosPromise<Array<GetChangeRequestDto>> {
            return localVarFp.getAllChangeRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all changeRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChangeRequests1(options?: any): AxiosPromise<Array<GetChangeRequestDto>> {
            return localVarFp.getAllChangeRequests1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a changeRequest for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequest(id: number, options?: any): AxiosPromise<GetChangeRequestDto> {
            return localVarFp.getChangeRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a validation image for a changeRequest with given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestValidationImage1(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getChangeRequestValidationImage1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all changeRequests regarding an object (id) of a given type
         * @param {number} objectId objectId
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsByObject1(objectId: number, type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any): AxiosPromise<Array<GetChangeRequestDto>> {
            return localVarFp.getChangeRequestsByObject1(objectId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all changeRequests regarding objects of a given type
         * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsByType1(type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any): AxiosPromise<Array<GetChangeRequestDto>> {
            return localVarFp.getChangeRequestsByType1(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangeRequestsV1Api - object-oriented interface
 * @export
 * @class ChangeRequestsV1Api
 * @extends {BaseAPI}
 */
export class ChangeRequestsV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new changeRequest
     * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public createChangeRequest(changeRequestToCreate: PostChangeRequestDto, options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).createChangeRequest(changeRequestToCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new changeRequest
     * @param {PostChangeRequestDto} changeRequestToCreate changeRequestToCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public createChangeRequest1(changeRequestToCreate: PostChangeRequestDto, options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).createChangeRequest1(changeRequestToCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a changeRequest
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public deleteChangeRequest(id: number, options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).deleteChangeRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all changeRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getAllChangeRequests(options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getAllChangeRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all changeRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getAllChangeRequests1(options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getAllChangeRequests1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a changeRequest for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getChangeRequest(id: number, options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getChangeRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a validation image for a changeRequest with given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getChangeRequestValidationImage1(id: number, options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getChangeRequestValidationImage1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all changeRequests regarding an object (id) of a given type
     * @param {number} objectId objectId
     * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getChangeRequestsByObject1(objectId: number, type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getChangeRequestsByObject1(objectId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all changeRequests regarding objects of a given type
     * @param {'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestsV1Api
     */
    public getChangeRequestsByType1(type: 'COURSE' | 'GENERAL' | 'LEARNINGPLACE' | 'OFFICE' | 'PERSON' | 'STUDENTUNION', options?: any) {
        return ChangeRequestsV1ApiFp(this.configuration).getChangeRequestsByType1(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationV1Api - axios parameter creator
 * @export
 */
export const ConfigurationV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new configuration
         * @param {PostConfigurationDto} postConfigurationDto postConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfig1: async (postConfigurationDto: PostConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postConfigurationDto' is not null or undefined
            assertParamExists('createConfig1', 'postConfigurationDto', postConfigurationDto)
            const localVarPath = `/api/configuration/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a configuration
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfig: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteConfig', 'name', name)
            const localVarPath = `/api/configuration/v1/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a configuration for a given name
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getConfig', 'name', name)
            const localVarPath = `/api/configuration/v1/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trigger FCM-message to refresh course data on android
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAndroidCourseData1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configuration/v1/refreshAndroid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a configuration
         * @param {string} name name
         * @param {PutConfigurationDto} putConfigurationDto putConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig: async (name: string, putConfigurationDto: PutConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateConfig', 'name', name)
            // verify required parameter 'putConfigurationDto' is not null or undefined
            assertParamExists('updateConfig', 'putConfigurationDto', putConfigurationDto)
            const localVarPath = `/api/configuration/v1/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationV1Api - functional programming interface
 * @export
 */
export const ConfigurationV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new configuration
         * @param {PostConfigurationDto} postConfigurationDto postConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConfig1(postConfigurationDto: PostConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConfig1(postConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a configuration
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfig(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfig(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a configuration for a given name
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trigger FCM-message to refresh course data on android
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAndroidCourseData1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAndroidCourseData1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a configuration
         * @param {string} name name
         * @param {PutConfigurationDto} putConfigurationDto putConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfig(name: string, putConfigurationDto: PutConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfig(name, putConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationV1Api - factory interface
 * @export
 */
export const ConfigurationV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new configuration
         * @param {PostConfigurationDto} postConfigurationDto postConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfig1(postConfigurationDto: PostConfigurationDto, options?: any): AxiosPromise<GetConfigurationDto> {
            return localVarFp.createConfig1(postConfigurationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a configuration
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfig(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConfig(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a configuration for a given name
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(name: string, options?: any): AxiosPromise<GetConfigurationDto> {
            return localVarFp.getConfig(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trigger FCM-message to refresh course data on android
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAndroidCourseData1(options?: any): AxiosPromise<void> {
            return localVarFp.refreshAndroidCourseData1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a configuration
         * @param {string} name name
         * @param {PutConfigurationDto} putConfigurationDto putConfigurationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(name: string, putConfigurationDto: PutConfigurationDto, options?: any): AxiosPromise<GetConfigurationDto> {
            return localVarFp.updateConfig(name, putConfigurationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationV1Api - object-oriented interface
 * @export
 * @class ConfigurationV1Api
 * @extends {BaseAPI}
 */
export class ConfigurationV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new configuration
     * @param {PostConfigurationDto} postConfigurationDto postConfigurationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationV1Api
     */
    public createConfig1(postConfigurationDto: PostConfigurationDto, options?: any) {
        return ConfigurationV1ApiFp(this.configuration).createConfig1(postConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a configuration
     * @param {string} name name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationV1Api
     */
    public deleteConfig(name: string, options?: any) {
        return ConfigurationV1ApiFp(this.configuration).deleteConfig(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a configuration for a given name
     * @param {string} name name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationV1Api
     */
    public getConfig(name: string, options?: any) {
        return ConfigurationV1ApiFp(this.configuration).getConfig(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trigger FCM-message to refresh course data on android
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationV1Api
     */
    public refreshAndroidCourseData1(options?: any) {
        return ConfigurationV1ApiFp(this.configuration).refreshAndroidCourseData1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a configuration
     * @param {string} name name
     * @param {PutConfigurationDto} putConfigurationDto putConfigurationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationV1Api
     */
    public updateConfig(name: string, putConfigurationDto: PutConfigurationDto, options?: any) {
        return ConfigurationV1ApiFp(this.configuration).updateConfig(name, putConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesV1Api - axios parameter creator
 * @export
 */
export const CoursesV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a new course
         * @param {PostCourseDto} postCourseDto postCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse1: async (postCourseDto: PostCourseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postCourseDto' is not null or undefined
            assertParamExists('createCourse1', 'postCourseDto', postCourseDto)
            const localVarPath = `/api/courses/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCourse', 'id', id)
            const localVarPath = `/api/courses/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/courses/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourse', 'id', id)
            const localVarPath = `/api/courses/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all ignored course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIgnoredCourse1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ignoredcourses/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a specific course by id
         * @param {number} id id
         * @param {PutCourseDto} putCourseDto putCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse: async (id: number, putCourseDto: PutCourseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCourse', 'id', id)
            // verify required parameter 'putCourseDto' is not null or undefined
            assertParamExists('updateCourse', 'putCourseDto', putCourseDto)
            const localVarPath = `/api/courses/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ignored course list
         * @param {Array<IgnoredCourseDto>} ignoredCourseDtos ignoredCourseDtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIgnoredCourses1: async (ignoredCourseDtos: Array<IgnoredCourseDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ignoredCourseDtos' is not null or undefined
            assertParamExists('updateIgnoredCourses1', 'ignoredCourseDtos', ignoredCourseDtos)
            const localVarPath = `/api/ignoredcourses/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ignoredCourseDtos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesV1Api - functional programming interface
 * @export
 */
export const CoursesV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoursesV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a new course
         * @param {PostCourseDto} postCourseDto postCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourse1(postCourseDto: PostCourseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCourse1(postCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourse(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCourse(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCourses1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCourses1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all ignored course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIgnoredCourse1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IgnoredCourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIgnoredCourse1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a specific course by id
         * @param {number} id id
         * @param {PutCourseDto} putCourseDto putCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCourse(id: number, putCourseDto: PutCourseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCourse(id, putCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update ignored course list
         * @param {Array<IgnoredCourseDto>} ignoredCourseDtos ignoredCourseDtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIgnoredCourses1(ignoredCourseDtos: Array<IgnoredCourseDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIgnoredCourses1(ignoredCourseDtos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoursesV1Api - factory interface
 * @export
 */
export const CoursesV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoursesV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a new course
         * @param {PostCourseDto} postCourseDto postCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse1(postCourseDto: PostCourseDto, options?: any): AxiosPromise<void> {
            return localVarFp.createCourse1(postCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourse(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCourse(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses1(options?: any): AxiosPromise<Array<GetCourseDto>> {
            return localVarFp.getAllCourses1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a specific course by id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(id: number, options?: any): AxiosPromise<GetCourseDto> {
            return localVarFp.getCourse(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all ignored course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIgnoredCourse1(options?: any): AxiosPromise<Array<IgnoredCourseDto>> {
            return localVarFp.getIgnoredCourse1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a specific course by id
         * @param {number} id id
         * @param {PutCourseDto} putCourseDto putCourseDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCourse(id: number, putCourseDto: PutCourseDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCourse(id, putCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update ignored course list
         * @param {Array<IgnoredCourseDto>} ignoredCourseDtos ignoredCourseDtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIgnoredCourses1(ignoredCourseDtos: Array<IgnoredCourseDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updateIgnoredCourses1(ignoredCourseDtos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesV1Api - object-oriented interface
 * @export
 * @class CoursesV1Api
 * @extends {BaseAPI}
 */
export class CoursesV1Api extends BaseAPI {
    /**
     * 
     * @summary creates a new course
     * @param {PostCourseDto} postCourseDto postCourseDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public createCourse1(postCourseDto: PostCourseDto, options?: any) {
        return CoursesV1ApiFp(this.configuration).createCourse1(postCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a specific course by id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public deleteCourse(id: number, options?: any) {
        return CoursesV1ApiFp(this.configuration).deleteCourse(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public getAllCourses1(options?: any) {
        return CoursesV1ApiFp(this.configuration).getAllCourses1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a specific course by id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public getCourse(id: number, options?: any) {
        return CoursesV1ApiFp(this.configuration).getCourse(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all ignored course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public getIgnoredCourse1(options?: any) {
        return CoursesV1ApiFp(this.configuration).getIgnoredCourse1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a specific course by id
     * @param {number} id id
     * @param {PutCourseDto} putCourseDto putCourseDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public updateCourse(id: number, putCourseDto: PutCourseDto, options?: any) {
        return CoursesV1ApiFp(this.configuration).updateCourse(id, putCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update ignored course list
     * @param {Array<IgnoredCourseDto>} ignoredCourseDtos ignoredCourseDtos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesV1Api
     */
    public updateIgnoredCourses1(ignoredCourseDtos: Array<IgnoredCourseDto>, options?: any) {
        return CoursesV1ApiFp(this.configuration).updateIgnoredCourses1(ignoredCourseDtos, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsV1Api - axios parameter creator
 * @export
 */
export const DepartmentsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary a list of all persons
         * @param {string} [course] course
         * @param {string} [faculty] faculty
         * @param {boolean} [isStudent] isStudent
         * @param {number} [personId] personId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentPersons1: async (course?: string, faculty?: string, isStudent?: boolean, personId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/v1/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (isStudent !== undefined) {
                localVarQueryParameter['isStudent'] = isStudent;
            }

            if (personId !== undefined) {
                localVarQueryParameter['personId'] = personId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all departments
         * @param {boolean} [onlyFaculty] onlyFaculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartments1: async (onlyFaculty?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyFaculty !== undefined) {
                localVarQueryParameter['onlyFaculty'] = onlyFaculty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a department for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartment', 'id', id)
            const localVarPath = `/api/departments/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a course for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentCourse1: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentCourse1', 'id', id)
            const localVarPath = `/api/departments/v1/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentCourses1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/v1/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ldap information about a person for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentPerson1: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentPerson1', 'id', id)
            const localVarPath = `/api/departments/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh persons and departments using ldap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshPersonsAndDepartments1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/v1/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * only the alias, poi_courses, courses and news_categories are editable
         * @summary update a specific department
         * @param {LdapFacultyDto} facultyDto facultyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment1: async (facultyDto: LdapFacultyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'facultyDto' is not null or undefined
            assertParamExists('updateDepartment1', 'facultyDto', facultyDto)
            const localVarPath = `/api/departments/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facultyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsV1Api - functional programming interface
 * @export
 */
export const DepartmentsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary a list of all persons
         * @param {string} [course] course
         * @param {string} [faculty] faculty
         * @param {boolean} [isStudent] isStudent
         * @param {number} [personId] personId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartmentPersons1(course?: string, faculty?: string, isStudent?: boolean, personId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLdapPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartmentPersons1(course, faculty, isStudent, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all departments
         * @param {boolean} [onlyFaculty] onlyFaculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartments1(onlyFaculty?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLdapFacultyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartments1(onlyFaculty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a department for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartment(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLdapFacultyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a course for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentCourse1(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLdapCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentCourse1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentCourses1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLdapCourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentCourses1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ldap information about a person for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentPerson1(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLdapPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentPerson1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh persons and departments using ldap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshPersonsAndDepartments1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshPersonsAndDepartments1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * only the alias, poi_courses, courses and news_categories are editable
         * @summary update a specific department
         * @param {LdapFacultyDto} facultyDto facultyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartment1(facultyDto: LdapFacultyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment1(facultyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsV1Api - factory interface
 * @export
 */
export const DepartmentsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary a list of all persons
         * @param {string} [course] course
         * @param {string} [faculty] faculty
         * @param {boolean} [isStudent] isStudent
         * @param {number} [personId] personId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentPersons1(course?: string, faculty?: string, isStudent?: boolean, personId?: number, options?: any): AxiosPromise<Array<GetLdapPersonDto>> {
            return localVarFp.getAllDepartmentPersons1(course, faculty, isStudent, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all departments
         * @param {boolean} [onlyFaculty] onlyFaculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartments1(onlyFaculty?: boolean, options?: any): AxiosPromise<Array<GetLdapFacultyDto>> {
            return localVarFp.getAllDepartments1(onlyFaculty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a department for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment(id: string, options?: any): AxiosPromise<GetLdapFacultyDto> {
            return localVarFp.getDepartment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a course for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentCourse1(id: string, options?: any): AxiosPromise<GetLdapCourseDto> {
            return localVarFp.getDepartmentCourse1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentCourses1(options?: any): AxiosPromise<Array<GetLdapCourseDto>> {
            return localVarFp.getDepartmentCourses1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ldap information about a person for a given id
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentPerson1(id: string, options?: any): AxiosPromise<GetLdapPersonDto> {
            return localVarFp.getDepartmentPerson1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh persons and departments using ldap
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshPersonsAndDepartments1(options?: any): AxiosPromise<void> {
            return localVarFp.refreshPersonsAndDepartments1(options).then((request) => request(axios, basePath));
        },
        /**
         * only the alias, poi_courses, courses and news_categories are editable
         * @summary update a specific department
         * @param {LdapFacultyDto} facultyDto facultyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment1(facultyDto: LdapFacultyDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateDepartment1(facultyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsV1Api - object-oriented interface
 * @export
 * @class DepartmentsV1Api
 * @extends {BaseAPI}
 */
export class DepartmentsV1Api extends BaseAPI {
    /**
     * 
     * @summary a list of all persons
     * @param {string} [course] course
     * @param {string} [faculty] faculty
     * @param {boolean} [isStudent] isStudent
     * @param {number} [personId] personId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getAllDepartmentPersons1(course?: string, faculty?: string, isStudent?: boolean, personId?: number, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getAllDepartmentPersons1(course, faculty, isStudent, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all departments
     * @param {boolean} [onlyFaculty] onlyFaculty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getAllDepartments1(onlyFaculty?: boolean, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getAllDepartments1(onlyFaculty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a department for a given id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartment(id: string, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getDepartment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a course for a given id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentCourse1(id: string, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentCourse1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentCourses1(options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentCourses1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ldap information about a person for a given id
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentPerson1(id: string, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentPerson1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh persons and departments using ldap
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public refreshPersonsAndDepartments1(options?: any) {
        return DepartmentsV1ApiFp(this.configuration).refreshPersonsAndDepartments1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * only the alias, poi_courses, courses and news_categories are editable
     * @summary update a specific department
     * @param {LdapFacultyDto} facultyDto facultyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public updateDepartment1(facultyDto: LdapFacultyDto, options?: any) {
        return DepartmentsV1ApiFp(this.configuration).updateDepartment1(facultyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErstiListV1Api - axios parameter creator
 * @export
 */
export const ErstiListV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get the existing ersti-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErstiList1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erstilist/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saving a new ersti-list, deletes all previous entries
         * @param {Array<Ersti>} erstis erstis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putErstiList1: async (erstis: Array<Ersti>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'erstis' is not null or undefined
            assertParamExists('putErstiList1', 'erstis', erstis)
            const localVarPath = `/api/erstilist/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(erstis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErstiListV1Api - functional programming interface
 * @export
 */
export const ErstiListV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErstiListV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get the existing ersti-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErstiList1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErstiList1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saving a new ersti-list, deletes all previous entries
         * @param {Array<Ersti>} erstis erstis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putErstiList1(erstis: Array<Ersti>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putErstiList1(erstis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErstiListV1Api - factory interface
 * @export
 */
export const ErstiListV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErstiListV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary get the existing ersti-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErstiList1(options?: any): AxiosPromise<object> {
            return localVarFp.getErstiList1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saving a new ersti-list, deletes all previous entries
         * @param {Array<Ersti>} erstis erstis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putErstiList1(erstis: Array<Ersti>, options?: any): AxiosPromise<object> {
            return localVarFp.putErstiList1(erstis, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErstiListV1Api - object-oriented interface
 * @export
 * @class ErstiListV1Api
 * @extends {BaseAPI}
 */
export class ErstiListV1Api extends BaseAPI {
    /**
     * 
     * @summary get the existing ersti-list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErstiListV1Api
     */
    public getErstiList1(options?: any) {
        return ErstiListV1ApiFp(this.configuration).getErstiList1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saving a new ersti-list, deletes all previous entries
     * @param {Array<Ersti>} erstis erstis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErstiListV1Api
     */
    public putErstiList1(erstis: Array<Ersti>, options?: any) {
        return ErstiListV1ApiFp(this.configuration).putErstiList1(erstis, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LearningPlacesV1Api - axios parameter creator
 * @export
 */
export const LearningPlacesV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary a list of occupations for all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLearningPlaceOccupations1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learningPlaceOccupations/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLearningPlaces1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/learningPlaces/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LearningPlacesV1Api - functional programming interface
 * @export
 */
export const LearningPlacesV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LearningPlacesV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary a list of occupations for all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLearningPlaceOccupations1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLearningPlaceOccupationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLearningPlaceOccupations1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLearningPlaces1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLearningPlaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLearningPlaces1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LearningPlacesV1Api - factory interface
 * @export
 */
export const LearningPlacesV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LearningPlacesV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary a list of occupations for all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLearningPlaceOccupations1(options?: any): AxiosPromise<Array<GetLearningPlaceOccupationDto>> {
            return localVarFp.getAllLearningPlaceOccupations1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available learning places
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLearningPlaces1(options?: any): AxiosPromise<Array<GetLearningPlaceDto>> {
            return localVarFp.getAllLearningPlaces1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LearningPlacesV1Api - object-oriented interface
 * @export
 * @class LearningPlacesV1Api
 * @extends {BaseAPI}
 */
export class LearningPlacesV1Api extends BaseAPI {
    /**
     * 
     * @summary a list of occupations for all available learning places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearningPlacesV1Api
     */
    public getAllLearningPlaceOccupations1(options?: any) {
        return LearningPlacesV1ApiFp(this.configuration).getAllLearningPlaceOccupations1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available learning places
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearningPlacesV1Api
     */
    public getAllLearningPlaces1(options?: any) {
        return LearningPlacesV1ApiFp(this.configuration).getAllLearningPlaces1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsV1Api - axios parameter creator
 * @export
 */
export const NewsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryToCreate postNewsCategoryToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsCategory1: async (postNewsCategoryToCreate: PostNewsCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postNewsCategoryToCreate' is not null or undefined
            assertParamExists('createNewsCategory1', 'postNewsCategoryToCreate', postNewsCategoryToCreate)
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNewsCategoryToCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this cascades down to corresponding news
         * @summary delete an existing news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsCategory1: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteNewsCategory1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNewsCategories1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNews', 'id', id)
            const localVarPath = `/api/news/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * if sendAll is set to \'false\', only the first 10 news ordered by date are returned
         * @summary a list of all available news for a given category
         * @param {number} categoryId categoryId
         * @param {boolean} [sendAll] sendAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsByCategory1: async (categoryId: number, sendAll?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsByCategory1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (sendAll !== undefined) {
                localVarQueryParameter['sendAll'] = sendAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategory1: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsCategory1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategoryDefaultImage1: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsCategoryDefaultImage1', 'categoryId', categoryId)
            const localVarPath = `/api/news/v1/category/image/{categoryId}.png`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @summary a list of news since given date for a given category
         * @param {number} categoryId categoryId
         * @param {string} updatedSince updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsSinceDateByCategory1: async (categoryId: number, updatedSince: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getNewsSinceDateByCategory1', 'categoryId', categoryId)
            // verify required parameter 'updatedSince' is not null or undefined
            assertParamExists('getNewsSinceDateByCategory1', 'updatedSince', updatedSince)
            const localVarPath = `/api/news/v1/since`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (updatedSince !== undefined) {
                localVarQueryParameter['updatedSince'] = updatedSince;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getThumbnailImage
         * @param {number} newsId newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailImageUsingGET1: async (newsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('getThumbnailImageUsingGET1', 'newsId', newsId)
            const localVarPath = `/api/news/v1/thumbnails/{newsId}`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * image must be a base64-encoded image, imageUrl will be set by server
         * @summary update an existing news category
         * @param {PutNewsCategoryDto} putNewsCategoryDto putNewsCategoryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsCategory1: async (putNewsCategoryDto: PutNewsCategoryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'putNewsCategoryDto' is not null or undefined
            assertParamExists('updateNewsCategory1', 'putNewsCategoryDto', putNewsCategoryDto)
            const localVarPath = `/api/news/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNewsCategoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsV1Api - functional programming interface
 * @export
 */
export const NewsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryToCreate postNewsCategoryToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewsCategory1(postNewsCategoryToCreate: PostNewsCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewsCategory1(postNewsCategoryToCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this cascades down to corresponding news
         * @summary delete an existing news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsCategory1(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNewsCategory1(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNewsCategories1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNewsCategories1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNews(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNews(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * if sendAll is set to \'false\', only the first 10 news ordered by date are returned
         * @summary a list of all available news for a given category
         * @param {number} categoryId categoryId
         * @param {boolean} [sendAll] sendAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsByCategory1(categoryId, sendAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCategory1(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCategory1(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCategoryDefaultImage1(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCategoryDefaultImage1(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @summary a list of news since given date for a given category
         * @param {number} categoryId categoryId
         * @param {string} updatedSince updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsSinceDateByCategory1(categoryId, updatedSince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getThumbnailImage
         * @param {number} newsId newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThumbnailImageUsingGET1(newsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThumbnailImageUsingGET1(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * image must be a base64-encoded image, imageUrl will be set by server
         * @summary update an existing news category
         * @param {PutNewsCategoryDto} putNewsCategoryDto putNewsCategoryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewsCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNewsCategory1(putNewsCategoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsV1Api - factory interface
 * @export
 */
export const NewsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new news category
         * @param {PostNewsCategoryDto} postNewsCategoryToCreate postNewsCategoryToCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsCategory1(postNewsCategoryToCreate: PostNewsCategoryDto, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.createNewsCategory1(postNewsCategoryToCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * this cascades down to corresponding news
         * @summary delete an existing news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsCategory1(categoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNewsCategory1(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all existing news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNewsCategories1(options?: any): AxiosPromise<Array<GetNewsCategoryDto>> {
            return localVarFp.getAllNewsCategories1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a news for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(id: number, options?: any): AxiosPromise<GetNewsDto> {
            return localVarFp.getNews(id, options).then((request) => request(axios, basePath));
        },
        /**
         * if sendAll is set to \'false\', only the first 10 news ordered by date are returned
         * @summary a list of all available news for a given category
         * @param {number} categoryId categoryId
         * @param {boolean} [sendAll] sendAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any): AxiosPromise<Array<GetNewsDto>> {
            return localVarFp.getNewsByCategory1(categoryId, sendAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a news category for a given id
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategory1(categoryId: number, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.getNewsCategory1(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the default image for given news category
         * @param {number} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategoryDefaultImage1(categoryId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getNewsCategoryDefaultImage1(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
         * @summary a list of news since given date for a given category
         * @param {number} categoryId categoryId
         * @param {string} updatedSince updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any): AxiosPromise<Array<GetNewsDto>> {
            return localVarFp.getNewsSinceDateByCategory1(categoryId, updatedSince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getThumbnailImage
         * @param {number} newsId newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailImageUsingGET1(newsId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getThumbnailImageUsingGET1(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * image must be a base64-encoded image, imageUrl will be set by server
         * @summary update an existing news category
         * @param {PutNewsCategoryDto} putNewsCategoryDto putNewsCategoryDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any): AxiosPromise<GetNewsCategoryDto> {
            return localVarFp.updateNewsCategory1(putNewsCategoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsV1Api - object-oriented interface
 * @export
 * @class NewsV1Api
 * @extends {BaseAPI}
 */
export class NewsV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new news category
     * @param {PostNewsCategoryDto} postNewsCategoryToCreate postNewsCategoryToCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public createNewsCategory1(postNewsCategoryToCreate: PostNewsCategoryDto, options?: any) {
        return NewsV1ApiFp(this.configuration).createNewsCategory1(postNewsCategoryToCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this cascades down to corresponding news
     * @summary delete an existing news category
     * @param {number} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public deleteNewsCategory1(categoryId: number, options?: any) {
        return NewsV1ApiFp(this.configuration).deleteNewsCategory1(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all existing news categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getAllNewsCategories1(options?: any) {
        return NewsV1ApiFp(this.configuration).getAllNewsCategories1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a news for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getNews(id: number, options?: any) {
        return NewsV1ApiFp(this.configuration).getNews(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * if sendAll is set to \'false\', only the first 10 news ordered by date are returned
     * @summary a list of all available news for a given category
     * @param {number} categoryId categoryId
     * @param {boolean} [sendAll] sendAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getNewsByCategory1(categoryId: number, sendAll?: boolean, options?: any) {
        return NewsV1ApiFp(this.configuration).getNewsByCategory1(categoryId, sendAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a news category for a given id
     * @param {number} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getNewsCategory1(categoryId: number, options?: any) {
        return NewsV1ApiFp(this.configuration).getNewsCategory1(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the default image for given news category
     * @param {number} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getNewsCategoryDefaultImage1(categoryId: number, options?: any) {
        return NewsV1ApiFp(this.configuration).getNewsCategoryDefaultImage1(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * FORMAT: yyyy-mm-dd (e.g. 2016-04-16)
     * @summary a list of news since given date for a given category
     * @param {number} categoryId categoryId
     * @param {string} updatedSince updatedSince
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getNewsSinceDateByCategory1(categoryId: number, updatedSince: string, options?: any) {
        return NewsV1ApiFp(this.configuration).getNewsSinceDateByCategory1(categoryId, updatedSince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getThumbnailImage
     * @param {number} newsId newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public getThumbnailImageUsingGET1(newsId: number, options?: any) {
        return NewsV1ApiFp(this.configuration).getThumbnailImageUsingGET1(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * image must be a base64-encoded image, imageUrl will be set by server
     * @summary update an existing news category
     * @param {PutNewsCategoryDto} putNewsCategoryDto putNewsCategoryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsV1Api
     */
    public updateNewsCategory1(putNewsCategoryDto: PutNewsCategoryDto, options?: any) {
        return NewsV1ApiFp(this.configuration).updateNewsCategory1(putNewsCategoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * POIV1Api - axios parameter creator
 * @export
 */
export const POIV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new course object
         * @param {PostPoiCourseDto} course course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiCourse1: async (course: PostPoiCourseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'course' is not null or undefined
            assertParamExists('createPoiCourse1', 'course', course)
            const localVarPath = `/api/poi/v1/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(course, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new general object
         * @param {PostPoiGeneralDto} general general
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiGeneral1: async (general: PostPoiGeneralDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'general' is not null or undefined
            assertParamExists('createPoiGeneral1', 'general', general)
            const localVarPath = `/api/poi/v1/generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(general, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new office object
         * @param {PostPoiOfficeDto} office office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiOffice1: async (office: PostPoiOfficeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'office' is not null or undefined
            assertParamExists('createPoiOffice1', 'office', office)
            const localVarPath = `/api/poi/v1/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(office, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new student union object
         * @param {PostPoiStudentUnionDto} studentUnion studentUnion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiStudentUnion1: async (studentUnion: PostPoiStudentUnionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUnion' is not null or undefined
            assertParamExists('createPoiStudentUnion1', 'studentUnion', studentUnion)
            const localVarPath = `/api/poi/v1/studentunions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studentUnion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a course object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiCourse1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePoiCourse1', 'id', id)
            const localVarPath = `/api/poi/v1/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a general object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiGeneral1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePoiGeneral1', 'id', id)
            const localVarPath = `/api/poi/v1/generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete an office object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiOffice1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePoiOffice1', 'id', id)
            const localVarPath = `/api/poi/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a student union object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiStudentUnion1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePoiStudentUnion1', 'id', id)
            const localVarPath = `/api/poi/v1/studentunions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all course objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiCourses1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all general objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiGenerals1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/generals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all office objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiOffices1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all studentUnion objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiStudentUnions1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/studentunions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a poi course for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiCourse1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoiCourse1', 'id', id)
            const localVarPath = `/api/poi/v1/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of all course objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiCoursesPage1: async (id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/courses/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a general object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiGeneral1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoiGeneral1', 'id', id)
            const localVarPath = `/api/poi/v1/generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of general objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiGeneralsPage1: async (id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/generals/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary an office object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiOffice1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoiOffice1', 'id', id)
            const localVarPath = `/api/poi/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of office objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiOfficesPage1: async (id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/offices/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a student union object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiStudentUnion1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoiStudentUnion1', 'id', id)
            const localVarPath = `/api/poi/v1/studentunions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of student union objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiStudentUnionsPage1: async (id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/poi/v1/studentunions/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a course object
         * @param {number} id id
         * @param {PutPoiCourseDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiCourse1: async (id: number, update: PutPoiCourseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePoiCourse1', 'id', id)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('updatePoiCourse1', 'update', update)
            const localVarPath = `/api/poi/v1/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a general object
         * @param {number} id id
         * @param {PutPoiGeneralDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiGeneral1: async (id: number, update: PutPoiGeneralDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePoiGeneral1', 'id', id)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('updatePoiGeneral1', 'update', update)
            const localVarPath = `/api/poi/v1/generals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an office object
         * @param {number} id id
         * @param {PutPoiOfficeDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiOffice1: async (id: number, update: PutPoiOfficeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePoiOffice1', 'id', id)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('updatePoiOffice1', 'update', update)
            const localVarPath = `/api/poi/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a student union object
         * @param {number} id id
         * @param {PutPoiStudentUnionDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiStudentUnion1: async (id: number, update: PutPoiStudentUnionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePoiStudentUnion1', 'id', id)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('updatePoiStudentUnion1', 'update', update)
            const localVarPath = `/api/poi/v1/studentunions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * POIV1Api - functional programming interface
 * @export
 */
export const POIV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = POIV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new course object
         * @param {PostPoiCourseDto} course course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoiCourse1(course: PostPoiCourseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoiCourse1(course, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new general object
         * @param {PostPoiGeneralDto} general general
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoiGeneral1(general: PostPoiGeneralDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiGeneralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoiGeneral1(general, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new office object
         * @param {PostPoiOfficeDto} office office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoiOffice1(office: PostPoiOfficeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiOfficeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoiOffice1(office, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new student union object
         * @param {PostPoiStudentUnionDto} studentUnion studentUnion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPoiStudentUnion1(studentUnion: PostPoiStudentUnionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiStudentUnionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPoiStudentUnion1(studentUnion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a course object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoiCourse1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoiCourse1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a general object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoiGeneral1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoiGeneral1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete an office object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoiOffice1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoiOffice1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a student union object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePoiStudentUnion1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePoiStudentUnion1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all course objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPoiCourses1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPoiCourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPoiCourses1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all general objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPoiGenerals1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPoiGeneralDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPoiGenerals1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all office objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPoiOffices1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPoiOfficeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPoiOffices1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all studentUnion objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPoiStudentUnions1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPoiStudentUnionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPoiStudentUnions1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a poi course for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiCourse1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiCourse1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of all course objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiCoursesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePointOfInterestCourse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiCoursesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a general object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiGeneral1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiGeneralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiGeneral1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of general objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiGeneralsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePointOfInterestGeneral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiGeneralsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary an office object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiOffice1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiOfficeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiOffice1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of office objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiOfficesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePointOfInterestOffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiOfficesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a student union object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiStudentUnion1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiStudentUnionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiStudentUnion1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of student union objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoiStudentUnionsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePointOfInterestStudentUnion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoiStudentUnionsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a course object
         * @param {number} id id
         * @param {PutPoiCourseDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePoiCourse1(id: number, update: PutPoiCourseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiCourseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePoiCourse1(id, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a general object
         * @param {number} id id
         * @param {PutPoiGeneralDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePoiGeneral1(id: number, update: PutPoiGeneralDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiGeneralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePoiGeneral1(id, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an office object
         * @param {number} id id
         * @param {PutPoiOfficeDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePoiOffice1(id: number, update: PutPoiOfficeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiOfficeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePoiOffice1(id, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a student union object
         * @param {number} id id
         * @param {PutPoiStudentUnionDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePoiStudentUnion1(id: number, update: PutPoiStudentUnionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiStudentUnionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePoiStudentUnion1(id, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * POIV1Api - factory interface
 * @export
 */
export const POIV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = POIV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new course object
         * @param {PostPoiCourseDto} course course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiCourse1(course: PostPoiCourseDto, options?: any): AxiosPromise<GetPoiCourseDto> {
            return localVarFp.createPoiCourse1(course, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new general object
         * @param {PostPoiGeneralDto} general general
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiGeneral1(general: PostPoiGeneralDto, options?: any): AxiosPromise<GetPoiGeneralDto> {
            return localVarFp.createPoiGeneral1(general, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new office object
         * @param {PostPoiOfficeDto} office office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiOffice1(office: PostPoiOfficeDto, options?: any): AxiosPromise<GetPoiOfficeDto> {
            return localVarFp.createPoiOffice1(office, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new student union object
         * @param {PostPoiStudentUnionDto} studentUnion studentUnion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPoiStudentUnion1(studentUnion: PostPoiStudentUnionDto, options?: any): AxiosPromise<GetPoiStudentUnionDto> {
            return localVarFp.createPoiStudentUnion1(studentUnion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a course object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiCourse1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePoiCourse1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a general object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiGeneral1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePoiGeneral1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete an office object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiOffice1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePoiOffice1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a student union object
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePoiStudentUnion1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePoiStudentUnion1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all course objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiCourses1(options?: any): AxiosPromise<Array<GetPoiCourseDto>> {
            return localVarFp.getAllPoiCourses1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all general objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiGenerals1(options?: any): AxiosPromise<Array<GetPoiGeneralDto>> {
            return localVarFp.getAllPoiGenerals1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all office objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiOffices1(options?: any): AxiosPromise<Array<GetPoiOfficeDto>> {
            return localVarFp.getAllPoiOffices1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all studentUnion objects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoiStudentUnions1(options?: any): AxiosPromise<Array<GetPoiStudentUnionDto>> {
            return localVarFp.getAllPoiStudentUnions1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a poi course for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiCourse1(id: number, options?: any): AxiosPromise<GetPoiCourseDto> {
            return localVarFp.getPoiCourse1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of all course objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiCoursesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PagePointOfInterestCourse> {
            return localVarFp.getPoiCoursesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a general object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiGeneral1(id: number, options?: any): AxiosPromise<GetPoiGeneralDto> {
            return localVarFp.getPoiGeneral1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of general objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiGeneralsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PagePointOfInterestGeneral> {
            return localVarFp.getPoiGeneralsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary an office object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiOffice1(id: number, options?: any): AxiosPromise<GetPoiOfficeDto> {
            return localVarFp.getPoiOffice1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of office objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiOfficesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PagePointOfInterestOffice> {
            return localVarFp.getPoiOfficesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a student union object for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiStudentUnion1(id: number, options?: any): AxiosPromise<GetPoiStudentUnionDto> {
            return localVarFp.getPoiStudentUnion1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of student union objects
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoiStudentUnionsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PagePointOfInterestStudentUnion> {
            return localVarFp.getPoiStudentUnionsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a course object
         * @param {number} id id
         * @param {PutPoiCourseDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiCourse1(id: number, update: PutPoiCourseDto, options?: any): AxiosPromise<GetPoiCourseDto> {
            return localVarFp.updatePoiCourse1(id, update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a general object
         * @param {number} id id
         * @param {PutPoiGeneralDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiGeneral1(id: number, update: PutPoiGeneralDto, options?: any): AxiosPromise<GetPoiGeneralDto> {
            return localVarFp.updatePoiGeneral1(id, update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an office object
         * @param {number} id id
         * @param {PutPoiOfficeDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiOffice1(id: number, update: PutPoiOfficeDto, options?: any): AxiosPromise<GetPoiOfficeDto> {
            return localVarFp.updatePoiOffice1(id, update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a student union object
         * @param {number} id id
         * @param {PutPoiStudentUnionDto} update update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePoiStudentUnion1(id: number, update: PutPoiStudentUnionDto, options?: any): AxiosPromise<GetPoiStudentUnionDto> {
            return localVarFp.updatePoiStudentUnion1(id, update, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * POIV1Api - object-oriented interface
 * @export
 * @class POIV1Api
 * @extends {BaseAPI}
 */
export class POIV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new course object
     * @param {PostPoiCourseDto} course course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public createPoiCourse1(course: PostPoiCourseDto, options?: any) {
        return POIV1ApiFp(this.configuration).createPoiCourse1(course, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new general object
     * @param {PostPoiGeneralDto} general general
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public createPoiGeneral1(general: PostPoiGeneralDto, options?: any) {
        return POIV1ApiFp(this.configuration).createPoiGeneral1(general, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new office object
     * @param {PostPoiOfficeDto} office office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public createPoiOffice1(office: PostPoiOfficeDto, options?: any) {
        return POIV1ApiFp(this.configuration).createPoiOffice1(office, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new student union object
     * @param {PostPoiStudentUnionDto} studentUnion studentUnion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public createPoiStudentUnion1(studentUnion: PostPoiStudentUnionDto, options?: any) {
        return POIV1ApiFp(this.configuration).createPoiStudentUnion1(studentUnion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a course object
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public deletePoiCourse1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).deletePoiCourse1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a general object
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public deletePoiGeneral1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).deletePoiGeneral1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete an office object
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public deletePoiOffice1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).deletePoiOffice1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a student union object
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public deletePoiStudentUnion1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).deletePoiStudentUnion1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all course objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getAllPoiCourses1(options?: any) {
        return POIV1ApiFp(this.configuration).getAllPoiCourses1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all general objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getAllPoiGenerals1(options?: any) {
        return POIV1ApiFp(this.configuration).getAllPoiGenerals1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all office objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getAllPoiOffices1(options?: any) {
        return POIV1ApiFp(this.configuration).getAllPoiOffices1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all studentUnion objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getAllPoiStudentUnions1(options?: any) {
        return POIV1ApiFp(this.configuration).getAllPoiStudentUnions1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a poi course for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiCourse1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiCourse1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of all course objects
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiCoursesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiCoursesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a general object for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiGeneral1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiGeneral1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of general objects
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiGeneralsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiGeneralsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary an office object for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiOffice1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiOffice1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of office objects
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiOfficesPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiOfficesPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a student union object for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiStudentUnion1(id: number, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiStudentUnion1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of student union objects
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public getPoiStudentUnionsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return POIV1ApiFp(this.configuration).getPoiStudentUnionsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a course object
     * @param {number} id id
     * @param {PutPoiCourseDto} update update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public updatePoiCourse1(id: number, update: PutPoiCourseDto, options?: any) {
        return POIV1ApiFp(this.configuration).updatePoiCourse1(id, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a general object
     * @param {number} id id
     * @param {PutPoiGeneralDto} update update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public updatePoiGeneral1(id: number, update: PutPoiGeneralDto, options?: any) {
        return POIV1ApiFp(this.configuration).updatePoiGeneral1(id, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an office object
     * @param {number} id id
     * @param {PutPoiOfficeDto} update update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public updatePoiOffice1(id: number, update: PutPoiOfficeDto, options?: any) {
        return POIV1ApiFp(this.configuration).updatePoiOffice1(id, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a student union object
     * @param {number} id id
     * @param {PutPoiStudentUnionDto} update update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POIV1Api
     */
    public updatePoiStudentUnion1(id: number, update: PutPoiStudentUnionDto, options?: any) {
        return POIV1ApiFp(this.configuration).updatePoiStudentUnion1(id, update, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonsV1Api - axios parameter creator
 * @export
 */
export const PersonsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new person
         * @param {PostPersonDto} postPersonDto postPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson1: async (postPersonDto: PostPersonDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPersonDto' is not null or undefined
            assertParamExists('createPerson1', 'postPersonDto', postPersonDto)
            const localVarPath = `/api/persons/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            const localVarPath = `/api/persons/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available persons
         * @param {string} [faculty] faculty
         * @param {string} [updatedSince] updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersons1: async (faculty?: string, updatedSince?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/persons/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (updatedSince !== undefined) {
                localVarQueryParameter['updatedSince'] = updatedSince;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a person for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/api/persons/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of all persons matching keyword
         * @param {string} keyword keyword
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonsByKeywordPage1: async (keyword: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getPersonsByKeywordPage1', 'keyword', keyword)
            const localVarPath = `/api/persons/v1/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a person
         * @param {number} id id
         * @param {PutPersonDto} putPersonDto putPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: number, putPersonDto: PutPersonDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            // verify required parameter 'putPersonDto' is not null or undefined
            assertParamExists('updatePerson', 'putPersonDto', putPersonDto)
            const localVarPath = `/api/persons/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonsV1Api - functional programming interface
 * @export
 */
export const PersonsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new person
         * @param {PostPersonDto} postPersonDto postPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson1(postPersonDto: PostPersonDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson1(postPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available persons
         * @param {string} [faculty] faculty
         * @param {string} [updatedSince] updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPersons1(faculty?: string, updatedSince?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPersons1(faculty, updatedSince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a person for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of all persons matching keyword
         * @param {string} keyword keyword
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonsByKeywordPage1(keyword: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGetPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonsByKeywordPage1(keyword, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a person
         * @param {number} id id
         * @param {PutPersonDto} putPersonDto putPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: number, putPersonDto: PutPersonDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, putPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonsV1Api - factory interface
 * @export
 */
export const PersonsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new person
         * @param {PostPersonDto} postPersonDto postPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson1(postPersonDto: PostPersonDto, options?: any): AxiosPromise<GetPersonDto> {
            return localVarFp.createPerson1(postPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a person
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available persons
         * @param {string} [faculty] faculty
         * @param {string} [updatedSince] updatedSince
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersons1(faculty?: string, updatedSince?: string, options?: any): AxiosPromise<Array<GetPersonDto>> {
            return localVarFp.getAllPersons1(faculty, updatedSince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a person for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: number, options?: any): AxiosPromise<GetPersonDto> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of all persons matching keyword
         * @param {string} keyword keyword
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonsByKeywordPage1(keyword: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PageGetPersonDto> {
            return localVarFp.getPersonsByKeywordPage1(keyword, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a person
         * @param {number} id id
         * @param {PutPersonDto} putPersonDto putPersonDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: number, putPersonDto: PutPersonDto, options?: any): AxiosPromise<GetPersonDto> {
            return localVarFp.updatePerson(id, putPersonDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonsV1Api - object-oriented interface
 * @export
 * @class PersonsV1Api
 * @extends {BaseAPI}
 */
export class PersonsV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new person
     * @param {PostPersonDto} postPersonDto postPersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public createPerson1(postPersonDto: PostPersonDto, options?: any) {
        return PersonsV1ApiFp(this.configuration).createPerson1(postPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a person
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public deletePerson(id: number, options?: any) {
        return PersonsV1ApiFp(this.configuration).deletePerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available persons
     * @param {string} [faculty] faculty
     * @param {string} [updatedSince] updatedSince
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public getAllPersons1(faculty?: string, updatedSince?: string, options?: any) {
        return PersonsV1ApiFp(this.configuration).getAllPersons1(faculty, updatedSince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a person for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public getPerson(id: number, options?: any) {
        return PersonsV1ApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of all persons matching keyword
     * @param {string} keyword keyword
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public getPersonsByKeywordPage1(keyword: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return PersonsV1ApiFp(this.configuration).getPersonsByKeywordPage1(keyword, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a person
     * @param {number} id id
     * @param {PutPersonDto} putPersonDto putPersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsV1Api
     */
    public updatePerson(id: number, putPersonDto: PutPersonDto, options?: any) {
        return PersonsV1ApiFp(this.configuration).updatePerson(id, putPersonDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomsV1Api - axios parameter creator
 * @export
 */
export const RoomsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new room
         * @param {PostRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom1: async (room: PostRoomDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'room' is not null or undefined
            assertParamExists('createRoom1', 'room', room)
            const localVarPath = `/api/rooms/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(room, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a room
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoom', 'id', id)
            const localVarPath = `/api/rooms/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of all available rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rooms/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a room for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoom', 'id', id)
            const localVarPath = `/api/rooms/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a page of all available rooms
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomsPage1: async (id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rooms/v1/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paged !== undefined) {
                localVarQueryParameter['paged'] = paged;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortSorted !== undefined) {
                localVarQueryParameter['sort.sorted'] = sortSorted;
            }

            if (sortUnsorted !== undefined) {
                localVarQueryParameter['sort.unsorted'] = sortUnsorted;
            }

            if (unpaged !== undefined) {
                localVarQueryParameter['unpaged'] = unpaged;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a room
         * @param {number} id id
         * @param {PutRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom: async (id: number, room: PutRoomDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoom', 'id', id)
            // verify required parameter 'room' is not null or undefined
            assertParamExists('updateRoom', 'room', room)
            const localVarPath = `/api/rooms/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(room, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomsV1Api - functional programming interface
 * @export
 */
export const RoomsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new room
         * @param {PostRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoom1(room: PostRoomDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoom1(room, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a room
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoom(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoom(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of all available rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRooms1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRoomDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRooms1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a room for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoom(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoom(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a page of all available rooms
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRoom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a room
         * @param {number} id id
         * @param {PutRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoom(id: number, room: PutRoomDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoomDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoom(id, room, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomsV1Api - factory interface
 * @export
 */
export const RoomsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new room
         * @param {PostRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom1(room: PostRoomDto, options?: any): AxiosPromise<GetRoomDto> {
            return localVarFp.createRoom1(room, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a room
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of all available rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms1(options?: any): AxiosPromise<Array<GetRoomDto>> {
            return localVarFp.getAllRooms1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a room for a given id
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom(id: number, options?: any): AxiosPromise<GetRoomDto> {
            return localVarFp.getRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a page of all available rooms
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {number} [offset] 
         * @param {boolean} [paged] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [sortSorted] 
         * @param {boolean} [sortUnsorted] 
         * @param {boolean} [unpaged] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any): AxiosPromise<PageRoom> {
            return localVarFp.getRoomsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a room
         * @param {number} id id
         * @param {PutRoomDto} room room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom(id: number, room: PutRoomDto, options?: any): AxiosPromise<GetRoomDto> {
            return localVarFp.updateRoom(id, room, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomsV1Api - object-oriented interface
 * @export
 * @class RoomsV1Api
 * @extends {BaseAPI}
 */
export class RoomsV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new room
     * @param {PostRoomDto} room room
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public createRoom1(room: PostRoomDto, options?: any) {
        return RoomsV1ApiFp(this.configuration).createRoom1(room, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a room
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public deleteRoom(id: number, options?: any) {
        return RoomsV1ApiFp(this.configuration).deleteRoom(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of all available rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public getAllRooms1(options?: any) {
        return RoomsV1ApiFp(this.configuration).getAllRooms1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a room for a given id
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public getRoom(id: number, options?: any) {
        return RoomsV1ApiFp(this.configuration).getRoom(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a page of all available rooms
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {number} [offset] 
     * @param {boolean} [paged] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [sortSorted] 
     * @param {boolean} [sortUnsorted] 
     * @param {boolean} [unpaged] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public getRoomsPage1(id?: string, name?: string, offset?: number, paged?: boolean, pageNumber?: number, pageSize?: number, sortSorted?: boolean, sortUnsorted?: boolean, unpaged?: boolean, options?: any) {
        return RoomsV1ApiFp(this.configuration).getRoomsPage1(id, name, offset, paged, pageNumber, pageSize, sortSorted, sortUnsorted, unpaged, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a room
     * @param {number} id id
     * @param {PutRoomDto} room room
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsV1Api
     */
    public updateRoom(id: number, room: PutRoomDto, options?: any) {
        return RoomsV1ApiFp(this.configuration).updateRoom(id, room, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SemestersV1Api - axios parameter creator
 * @export
 */
export const SemestersV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary a list of all available semesters
         * @param {string} [faculty] faculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSemesters1: async (faculty?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/semesters/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a semester for a given id
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemester: async (semesterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'semesterId' is not null or undefined
            assertParamExists('getSemester', 'semesterId', semesterId)
            const localVarPath = `/api/semesters/v1/{semesterId}`
                .replace(`{${"semesterId"}}`, encodeURIComponent(String(semesterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterICal: async (semesterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'semesterId' is not null or undefined
            assertParamExists('getSemesterICal', 'semesterId', semesterId)
            const localVarPath = `/api/semesters/v1/{semesterId}/ical`
                .replace(`{${"semesterId"}}`, encodeURIComponent(String(semesterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterICalExt: async (semesterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'semesterId' is not null or undefined
            assertParamExists('getSemesterICalExt', 'semesterId', semesterId)
            const localVarPath = `/api/semesters/v1/{semesterId}/ical.ics`
                .replace(`{${"semesterId"}}`, encodeURIComponent(String(semesterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SemestersV1Api - functional programming interface
 * @export
 */
export const SemestersV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SemestersV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary a list of all available semesters
         * @param {string} [faculty] faculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSemesters1(faculty?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSemesterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSemesters1(faculty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a semester for a given id
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemester(semesterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSemesterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemester(semesterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemesterICal(semesterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemesterICal(semesterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemesterICalExt(semesterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemesterICalExt(semesterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SemestersV1Api - factory interface
 * @export
 */
export const SemestersV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SemestersV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary a list of all available semesters
         * @param {string} [faculty] faculty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSemesters1(faculty?: string, options?: any): AxiosPromise<Array<GetSemesterDto>> {
            return localVarFp.getAllSemesters1(faculty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a semester for a given id
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemester(semesterId: number, options?: any): AxiosPromise<GetSemesterDto> {
            return localVarFp.getSemester(semesterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterICal(semesterId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getSemesterICal(semesterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get iCal-file of a semester
         * @param {number} semesterId semesterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemesterICalExt(semesterId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getSemesterICalExt(semesterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SemestersV1Api - object-oriented interface
 * @export
 * @class SemestersV1Api
 * @extends {BaseAPI}
 */
export class SemestersV1Api extends BaseAPI {
    /**
     * 
     * @summary a list of all available semesters
     * @param {string} [faculty] faculty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SemestersV1Api
     */
    public getAllSemesters1(faculty?: string, options?: any) {
        return SemestersV1ApiFp(this.configuration).getAllSemesters1(faculty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a semester for a given id
     * @param {number} semesterId semesterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SemestersV1Api
     */
    public getSemester(semesterId: number, options?: any) {
        return SemestersV1ApiFp(this.configuration).getSemester(semesterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get iCal-file of a semester
     * @param {number} semesterId semesterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SemestersV1Api
     */
    public getSemesterICal(semesterId: number, options?: any) {
        return SemestersV1ApiFp(this.configuration).getSemesterICal(semesterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get iCal-file of a semester
     * @param {number} semesterId semesterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SemestersV1Api
     */
    public getSemesterICalExt(semesterId: number, options?: any) {
        return SemestersV1ApiFp(this.configuration).getSemesterICalExt(semesterId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsV1Api - axios parameter creator
 * @export
 */
export const SettingsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieve the settings for the grade update intervals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsGradeUpdateInterval1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsV1Api - functional programming interface
 * @export
 */
export const SettingsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieve the settings for the grade update intervals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsGradeUpdateInterval1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsGradeUpdateInterval1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsV1Api - factory interface
 * @export
 */
export const SettingsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieve the settings for the grade update intervals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsGradeUpdateInterval1(options?: any): AxiosPromise<SettingsDto> {
            return localVarFp.getSettingsGradeUpdateInterval1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsV1Api - object-oriented interface
 * @export
 * @class SettingsV1Api
 * @extends {BaseAPI}
 */
export class SettingsV1Api extends BaseAPI {
    /**
     * 
     * @summary retrieve the settings for the grade update intervals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsV1Api
     */
    public getSettingsGradeUpdateInterval1(options?: any) {
        return SettingsV1ApiFp(this.configuration).getSettingsGradeUpdateInterval1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersV1Api - axios parameter creator
 * @export
 */
export const UsersV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new user
         * @param {UserDto} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser1: async (user: UserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createUser1', 'user', user)
            const localVarPath = `/api/users/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a principal object of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/v1/_me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update own password
         * @param {PasswordChangeDto} passwordChangeDto passwordChangeDto
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword1: async (passwordChangeDto: PasswordChangeDto, name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeDto' is not null or undefined
            assertParamExists('updateOwnPassword1', 'passwordChangeDto', passwordChangeDto)
            const localVarPath = `/api/users/v1/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersV1Api - functional programming interface
 * @export
 */
export const UsersV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new user
         * @param {UserDto} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser1(user: UserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser1(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a principal object of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HKAUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update own password
         * @param {PasswordChangeDto} passwordChangeDto passwordChangeDto
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnPassword1(passwordChangeDto: PasswordChangeDto, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnPassword1(passwordChangeDto, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersV1Api - factory interface
 * @export
 */
export const UsersV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new user
         * @param {UserDto} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser1(user: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.createUser1(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a principal object of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser1(options?: any): AxiosPromise<HKAUser> {
            return localVarFp.getCurrentUser1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update own password
         * @param {PasswordChangeDto} passwordChangeDto passwordChangeDto
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword1(passwordChangeDto: PasswordChangeDto, name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateOwnPassword1(passwordChangeDto, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersV1Api - object-oriented interface
 * @export
 * @class UsersV1Api
 * @extends {BaseAPI}
 */
export class UsersV1Api extends BaseAPI {
    /**
     * 
     * @summary create a new user
     * @param {UserDto} user user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersV1Api
     */
    public createUser1(user: UserDto, options?: any) {
        return UsersV1ApiFp(this.configuration).createUser1(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a principal object of the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersV1Api
     */
    public getCurrentUser1(options?: any) {
        return UsersV1ApiFp(this.configuration).getCurrentUser1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update own password
     * @param {PasswordChangeDto} passwordChangeDto passwordChangeDto
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersV1Api
     */
    public updateOwnPassword1(passwordChangeDto: PasswordChangeDto, name?: string, options?: any) {
        return UsersV1ApiFp(this.configuration).updateOwnPassword1(passwordChangeDto, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApiApi - axios parameter creator
 * @export
 */
export const VersionApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary a build info with server version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/build-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a git info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gitInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/git-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApiApi - functional programming interface
 * @export
 */
export const VersionApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary a build info with server version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a git info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gitInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gitInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApiApi - factory interface
 * @export
 */
export const VersionApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiApiFp(configuration)
    return {
        /**
         * 
         * @summary a build info with server version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options?: any): AxiosPromise<BuildProperties> {
            return localVarFp.buildInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a git info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gitInfo(options?: any): AxiosPromise<GitProperties> {
            return localVarFp.gitInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApiApi - object-oriented interface
 * @export
 * @class VersionApiApi
 * @extends {BaseAPI}
 */
export class VersionApiApi extends BaseAPI {
    /**
     * 
     * @summary a build info with server version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiApi
     */
    public buildInfo(options?: any) {
        return VersionApiApiFp(this.configuration).buildInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a git info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApiApi
     */
    public gitInfo(options?: any) {
        return VersionApiApiFp(this.configuration).gitInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


