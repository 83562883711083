import {
  BuildingV1Api,
  IdWrapper,
  RoomsV1Api,
  POIV1Api,
  GetBuildingDto,
  GetRoomDto,
  GetPoiGeneralDto,
  GetPoiOfficeDto,
  GetPoiStudentUnionDto,
  Room,
} from '@/api-client/broker'

import { GeneralInstitution, InstitutionType } from '@/types/campus'

export const namespaced = true

interface CampusState {
  buildings: GetBuildingDto[]
  rooms: GetRoomDto[]
  generals: GeneralInstitution[]
  offices: GetPoiOfficeDto[]
  studentsUnions: GetPoiStudentUnionDto[]
  poiCourses: GetPoiGeneralDto[]
}

export const state: CampusState = {
  buildings: [],
  rooms: [],
  generals: [],
  offices: [],
  studentsUnions: [],
  poiCourses: [],
}

const BuildingAPI = new BuildingV1Api()
const RoomAPI = new RoomsV1Api()

const PoiGeneralApi = new POIV1Api()

export const mutations = {
  SET_BUILDINGS(state: CampusState, buildings) {
    state.buildings = buildings.filter((building) => !building.deleted)
  },
  SET_ROOMS(state: CampusState, rooms: GetRoomDto[]) {
    state.rooms = rooms
      .filter((room) => !room.deleted)
      .map((room) => {
        if (room.building) {
          room.building = state.buildings.find(
            (building) => building.id === room.building.id
          ) as unknown as IdWrapper
        }

        return room
      })
  },
  SET_GENERALS(state: CampusState, generals: GetPoiGeneralDto[]) {
    state.generals = generals as unknown as GeneralInstitution[]

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state.generals.forEach((general, index) => {
      state.generals[index].type = InstitutionType.GENERAL
    })
  },
  SET_OFFICES(state: CampusState, offices) {
    state.offices = offices.map((office) => {
      if (office.room) {
        office.room = state.rooms.find((room) => room.id === office.room.id)
      }

      return { ...office, type: InstitutionType.OFFICE }
    })
  },
  SET_STUDENTSUNIONS(state: CampusState, studentUnions) {
    state.studentsUnions = studentUnions.map((studentUnion) => {
      if (studentUnion.room) {
        studentUnion.room = state.rooms.find(
          (room) => room.id === studentUnion.room.id
        )
      }

      return { ...studentUnion, type: InstitutionType.STUDENT_UNION }
    })
  },
  SET_POICOURSES(state: CampusState, response) {
    state.poiCourses = response
  },
}

export const actions = {
  async getAllBuildings({ commit }) {
    const response = await BuildingAPI.getAllBuildings1().catch((err) => err)
    if (response.data) {
      commit('SET_BUILDINGS', response.data)
    }
  },
  async getAllRooms({ commit }) {
    const response = await RoomAPI.getAllRooms1().catch((err) => err)
    if (response.data) {
      commit('SET_ROOMS', response.data)
    }
  },

  async getAllGenerals({ commit }) {
    const response = await PoiGeneralApi.getAllPoiGenerals1().catch(
      (err) => err
    )
    if (response.data) {
      const data = response.data as unknown as GeneralInstitution[]
      data.forEach(async (general, index) => {
        data[index] = (await PoiGeneralApi.getPoiGeneral1(general.id))
          .data as unknown as GeneralInstitution
        data[index].room.building = (
          await BuildingAPI.getBuilding(data[index].room.building.id)
        ).data
        data[index].type = InstitutionType.GENERAL
      })
      commit('SET_GENERALS', data)
    }
  },
  async getAllOffices({ commit }) {
    const response = await PoiGeneralApi.getAllPoiOffices1().catch((err) => err)
    if (response.data) {
      commit('SET_OFFICES', response.data)
    }
  },
  async getPoiCourses({ commit }) {
    const response = await PoiGeneralApi.getAllPoiCourses1().catch((err) => err)
    if (response.data) {
      commit('SET_POICOURSES', response.data)
    }
  },
  async getAllStudentsUnions({ commit }) {
    const response = await PoiGeneralApi.getAllPoiStudentUnions1().catch(
      (err) => err
    )
    if (response.data) {
      commit('SET_STUDENTSUNIONS', response.data)
    }
  },
  async getAllItems({ state, dispatch }) {
    if (state.buildings.length === 0) {
      await dispatch('getAllBuildings')
    }

    if (state.rooms.length === 0) {
      await dispatch('getAllRooms')
    }

    if (state.generals.length === 0) {
      await dispatch('getAllGenerals')
    }

    if (state.poiCourses.length === 0) {
      await dispatch('getPoiCourses')
    }

    if (state.offices.length === 0) {
      await dispatch('getAllOffices')
    }

    if (state.studentsUnions.length === 0) {
      await dispatch('getAllStudentsUnions')
    }
  },
}

export const getters = {}
