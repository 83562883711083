export default {
  __name: 'error',
  props: {
  error: {
    type: Object,
    default: null,
  },
},
  setup(__props) {

const props = __props;

/* eslint-disable */
/**
 * todo: add when migrating to nuxt 3
 * useHead({
 * title: this.error.statusCode === 404 ? this.pageNotFound : this.otherError
 * })
 *
 * definePageMeta({
 *   layout: 'empty',
 * })
 */


const pageNotFound = '404 Not Found'
const otherError = 'An error occurred'


return { __sfc: true,props, pageNotFound, otherError }
}

}