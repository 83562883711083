import {NewsApiApi} from '@/api-client/news'
import {NewsCategory} from '@/types/news'

const defaults = {
  categories: [],
}

const NewsApi = new NewsApiApi()

export const namespaced = true
export const state = Object.assign({}, defaults)

export const mutations = {
  SET_CATEGORIES(state, options) {
    state.categories = Object.assign([], defaults.categories, options)
  },
}

export const getters = {
  sortCategories(state) {
    const categories: NewsCategory[] = state.categories

    return (faculty) => {
      return Array.from(categories).sort((a, b) => {
        let cmp = 0

        if (a.faculty && b.faculty) {
          if (a.faculty.id !== faculty && b.faculty.id === faculty) {
            cmp = 1
          } else if (a.faculty.id === faculty && b.faculty.id !== faculty) {
            cmp = -1
          } else if (a.faculty.id > b.faculty.id) {
            cmp = 1
          } else if (a.faculty.id < b.faculty.id) {
            cmp = -1
          }
        } else if (!a.faculty && b.faculty) {
          if (b.faculty.id === faculty) {
            cmp = 1
          } else {
            cmp = -1
          }
        } else if (a.faculty && !b.faculty) {
          if (a.faculty.id === faculty) {
            cmp = -1
          } else {
            cmp = 1
          }
        }

        return cmp
      })
    }
  },
}

export const actions = {
  async fetchCategories({ commit }): Promise<NewsCategory[]> {
    const { data } = await NewsApi.getAllNewsCategories1()

    const categoriesPromise = (data as NewsCategory[]).map(async (cat) => {
      const news = await NewsApi.getNewsByCategory1(cat.id, false)

      const category = cat as NewsCategory

      category.news = news.data

      return category
    })
    const categories = await Promise.allSettled(categoriesPromise)
    const categoriesSettled: NewsCategory[] = []
    categories.forEach((element) => {
      if (element.status === 'fulfilled') categoriesSettled.push(element.value)
    })

    commit('SET_CATEGORIES', categoriesSettled)

    return categoriesSettled
  },
}
