// eslint-disable-next-line camelcase

import { CanteenV1Api } from '@/api-client/canteen'

export const namespaced = true

export const state = {
  canteens: [],
}

const api = new CanteenV1Api()

export const mutations = {
  SET_CANTEENS(state, response) {
    state.canteens = response
  },
}

export const actions = {
  getCanteens({ commit }) {
    return api.getAllCanteens1().then((response) => {
      commit('SET_CANTEENS', response.data)
    })
  },
}

export const getters = {}
