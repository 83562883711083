import {  BuildingV1Api,    RoomsV1Api } from '@/api-client/broker'
// eslint-disable-next-line camelcase

export const namespaced = true

export const state = {
  building: null,
  room: null,
}

const RoomApi = new RoomsV1Api()
const BuildingApi = new BuildingV1Api()

export const mutations = {
  SET_ROOM(state, response) {
    state.room = response
  },
  SET_BUILDING(state, response) {
    state.building = response
  },
}

export const actions = {
  async getRoomById({ commit }, id) {
    return await RoomApi.getRoom(id).then((response) => {
      commit('SET_ROOM', response.data)
    })
  },
  async getBuildingById({ commit }, id) {
    return await BuildingApi.getBuilding(id).then((response) => {
      commit('SET_BUILDING', response.data)
    })
  },
  resetRoomAndBuilding({ commit }) {
    commit('SET_BUILDING', null)
    commit('SET_ROOM', null)
  },
}

export const getters = {}
