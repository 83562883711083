import {
  GetRoomDto,
  LdapFaculty,
  Room,
  GetLdapFacultyDto,
  GetPoiOfficeDto,
  GetPoiStudentUnionDto,
} from '@/api-client/broker'
import { LocaleMessages } from 'vue-i18n'

export enum InstitutionType {
  GENERAL,
  OFFICE,
  STUDENT_UNION,
}

export interface GeneralInstitution {
  email?: string
  faculty?: LdapFaculty
  id?: number
  name?: string
  openingHours?: string
  phone?: string
  room?: GetRoomDto
  type?: InstitutionType.GENERAL
  updatedAt?: string
  url?: string
}

export interface StudentUnion {
  id: number
  type: InstitutionType.STUDENT_UNION
  name: string
  email: string
  phone: string
  url: string
  urlTwo: string
  faculty: GetLdapFacultyDto
  facultyId: string
  meeting: string
  room: Room
  updatedAt: number
}
export interface Category {
  name: string | LocaleMessages
  value?: InstitutionType
  items?: GeneralInstitution[] | GetPoiOfficeDto[] | GetPoiStudentUnionDto[]
  group?: string
  categories?: Category[]
}

export interface Group {
  name: string | LocaleMessages
  value?: InstitutionType
  items?: GeneralInstitution[] | GetPoiOfficeDto[] | GetPoiStudentUnionDto[]
  group?: string
  categories?: Category[]
  subgroups?: Group[]
}
