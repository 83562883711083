/* tslint:disable */
/* eslint-disable */
/**
 * HKA-APP Canteen Production-Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ErrorResponse
     */
    content?: object;
}
/**
 * 
 * @export
 * @interface GetCanteenDto
 */
export interface GetCanteenDto {
    /**
     * 
     * @type {number}
     * @memberof GetCanteenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCanteenDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCanteenDto
     */
    updatedAt?: number;
}
/**
 * 
 * @export
 * @interface GetCanteenPlanDto
 */
export interface GetCanteenPlanDto {
    /**
     * 
     * @type {string}
     * @memberof GetCanteenPlanDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCanteenPlanDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCanteenPlanDto
     */
    planDate?: number;
    /**
     * 
     * @type {Array<Line>}
     * @memberof GetCanteenPlanDto
     */
    lines?: Array<Line>;
}
/**
 * 
 * @export
 * @interface Line
 */
export interface Line {
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Line
     */
    name: string;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof Line
     */
    meals?: Array<Meal>;
    /**
     * 
     * @type {string}
     * @memberof Line
     */
    closingStart?: string;
    /**
     * 
     * @type {string}
     * @memberof Line
     */
    closingEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Line
     * @deprecated
     */
    noData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Line
     */
    closed?: boolean;
}
/**
 * 
 * @export
 * @interface Meal
 */
export interface Meal {
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    dish?: string;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    additives?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    bio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    fish?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    pork?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    porkAw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    cow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    cowAw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    vegan?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    veg?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    mensaVit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    priceFlag?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    price1?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    price2?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    price3?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    price4?: number;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    meal?: string;
}

/**
 * CanteenV1Api - axios parameter creator
 * @export
 */
export const CanteenV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary a list of all available canteens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCanteens1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/canteen/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of detailed canteen plan for a given day
         * @param {number} canteenId 
         * @param {string} planDate Format: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanteenPlan: async (canteenId: number, planDate: string, days?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'canteenId' is not null or undefined
            assertParamExists('getCanteenPlan', 'canteenId', canteenId)
            // verify required parameter 'planDate' is not null or undefined
            assertParamExists('getCanteenPlan', 'planDate', planDate)
            const localVarPath = `/api/canteen/v1/{canteenId}/date/{planDate}`
                .replace(`{${"canteenId"}}`, encodeURIComponent(String(canteenId)))
                .replace(`{${"planDate"}}`, encodeURIComponent(String(planDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a meal icon file
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealIcon: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getMealIcon', 'name', name)
            const localVarPath = `/api/canteen/v1/icon/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary a list of available meal icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealIcons1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/canteen/v1/icons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanteenV1Api - functional programming interface
 * @export
 */
export const CanteenV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanteenV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary a list of all available canteens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCanteens1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCanteenDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCanteens1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of detailed canteen plan for a given day
         * @param {number} canteenId 
         * @param {string} planDate Format: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanteenPlan(canteenId: number, planDate: string, days?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCanteenPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanteenPlan(canteenId, planDate, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a meal icon file
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealIcon(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealIcon(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary a list of available meal icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealIcons1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealIcons1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanteenV1Api - factory interface
 * @export
 */
export const CanteenV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanteenV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary a list of all available canteens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCanteens1(options?: any): AxiosPromise<Array<GetCanteenDto>> {
            return localVarFp.getAllCanteens1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of detailed canteen plan for a given day
         * @param {number} canteenId 
         * @param {string} planDate Format: yyyy-mm-dd (e.g. 2016-04-16)
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanteenPlan(canteenId: number, planDate: string, days?: number, options?: any): AxiosPromise<Array<GetCanteenPlanDto>> {
            return localVarFp.getCanteenPlan(canteenId, planDate, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a meal icon file
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealIcon(name: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getMealIcon(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary a list of available meal icons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealIcons1(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getMealIcons1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanteenV1Api - object-oriented interface
 * @export
 * @class CanteenV1Api
 * @extends {BaseAPI}
 */
export class CanteenV1Api extends BaseAPI {
    /**
     * 
     * @summary a list of all available canteens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanteenV1Api
     */
    public getAllCanteens1(options?: any) {
        return CanteenV1ApiFp(this.configuration).getAllCanteens1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of detailed canteen plan for a given day
     * @param {number} canteenId 
     * @param {string} planDate Format: yyyy-mm-dd (e.g. 2016-04-16)
     * @param {number} [days] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanteenV1Api
     */
    public getCanteenPlan(canteenId: number, planDate: string, days?: number, options?: any) {
        return CanteenV1ApiFp(this.configuration).getCanteenPlan(canteenId, planDate, days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a meal icon file
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanteenV1Api
     */
    public getMealIcon(name: string, options?: any) {
        return CanteenV1ApiFp(this.configuration).getMealIcon(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary a list of available meal icons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanteenV1Api
     */
    public getMealIcons1(options?: any) {
        return CanteenV1ApiFp(this.configuration).getMealIcons1(options).then((request) => request(this.axios, this.basePath));
    }
}


