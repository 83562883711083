export default {
  welcome: 'Hallo',

  SelectLanguage: 'Sprache',

  // Toolbar
  News: 'News',
  Freshman: 'Studi-Hilfe',
  Campus: 'Campus',
  People: 'Personen',
  Canteen: 'Mensa',
  Timetable: 'Kalender',
  LearningPlaces: 'Lernplätze',
  TrainStations: 'Haltestellen',
  InstallApp: 'App installieren?',
  Faculty: 'Fakultät',
  FacultyShort: 'Fak',

  // CategoryMasonry
  NoEntryCategory: 'Es gibt leider keinen Eintrag in dieser Kategorie',

  // FormGenerator
  Other: 'Andere',

  // News Page
  FilterNews: 'News filtern',
  SearchNews: 'News suchen',
  Reports: 'Meldungen',
  Press: 'Presse',
  ToArticle: 'Zum Artikel',
  PublishedAt: 'Veröffentlicht am',
  Feedback: 'Feedback',
  Impressum: 'Impressum',
  FAQ: 'FAQ',

  // Campus
  General: 'Allgemein',
  Office: 'Sekretariat',
  StudentAssociation: 'Fachschaft',

  // Office Card
  Room: 'Raum',
  Phone: 'Telefon',
  ConsultationHours: 'Sprechzeiten',
  LearnMore: 'Mehr Erfahren',
  Edit: 'Bearbeiten',
  Close: 'Schließen',
  LastModified: 'Zuletzt geändert am',

  // People
  ChooseFaculty: 'Fakultät(en) auswählen',
  SearchForPeople: 'Nach Personen suchen',

  // edit

  PersonEdit: 'Person bearbeiten',
  RequestChange: 'Änderung beantragen',

  // Canteen
  SelectLocation: 'Standort auswählen',
  SelectRole: 'Rolle auswählen',
  SelectDate: 'Datum auswählen',
  LocationClosed: 'Keine Information für diesen Tag!',
  Closed: 'Geschlossen',
  Today: 'Heute',
  Undergraduate: 'Student',
  Guest: 'Gast',
  Student: 'Schüler',
  Employee: 'Mitarbeiter',

  Wahlessen1: 'Wahlessen 1',
  Wahlessen2: 'Wahlessen 2',
  Wahlessen3: 'Wahlessen 3',
  Linie1: 'Linie 1',
  Linie2: 'Linie 2',
  Linie3: 'Linie 3',
  Linie45: 'Linie 4/5',
  L6Update: 'Linie 6 Update',
  l6: 'L6',
  l5: 'L5',
  CurryQueen: 'Curry Queen',
  GutGünstig: 'Gut & Günstig',
  Schnitzelbar: 'Schnitzelbar',
  Aktionstheke: 'Aktionstheke',
  wahl3: 'Wahlessen 3',
  GutGünstig2: 'Gut & Günstig 2',
  Cafeteriaab1430: 'Cafeteria ab 14:30',
  CafeteriaHeißeTheke: 'Cafeteria Heiße Theke',
  Abend: 'Abend',
  pizza: 'Pizza',
  pasta: 'Pasta',
  Buffet: 'Buffet',
  salat_dessert: 'Salat / Dessert',

  // FAQ
  RequestQuestion:
    'Warum dauert es so lange, dass meine Anfrage bearbeitet wird',
  RequestAnswer:
    'Das Support-Team der HKA-APP ist sehr darum bemüht, dir schnellstmöglich eine qualitative und hilfreiche Antwort auf deine Anfrage zu geben. Wir bitten dich um etwas Geduld, da es sich bei der HKA-APP um ein kleines Team von Studierenden handelt, die das Projekt parallel zum eigenen Studium realisieren. Durch die fortlaufende Neubesetzung der jeweiligen Bereiche nach jedem Semester können zusätzlich längere Bearbeitungszeiten auftreten.',
  BrowserQuestion: 'Welchen Browser kann ich für die WebApp benutzen',
  BrowserAnswer:
    'Die WebApp läuft auf jedem Browser, der JavaScript unterstützt. Sprich: Google Chrome, Mozilla Firefox, Internet Explorer,Microsoft Edge, ...',
  ServerQuestion:
    'Beim Abfragen von Serverdaten bekomme ich einen Fehler. Was soll ich tun',
  ServerAnswer:
    'Warte einige Stunden. Mit aller Wahrscheinlichkeit handelt es sich um einen Serverfehler. Ansonsten schicke uns eine E-Mail an info@hskampus.de oder kontaktiere uns über den Feedback-Tab in der Fußzeile',
  QuestionPersonData:
    'Warum sind einige Raumnummern und Daten zu Personen fehlerhaft',
  AnswerPersonData:
    ' Die Daten werden von verschiedensten Quellen (LSF, Hochschul-Website, etc.) gesammelt und aufbereitet. Leider kann es hin und wieder vorkommen, dass diese nicht zeitnah aktualisiert werden.',

  QuestionTimetable:
    'Mein Stundenplan ist leer oder fehlerhaft. Woran liegt das',
  AnswerTimetable:
    ' HKA-APP dient lediglich als Vermittler, dass bedeutet, wir bereiten Daten qualitativ auf, die wir aus verschiedensten Quellen erhalten. Wir haben keinen Einfluss auf die Quelldaten, es kann also passieren, dass keine bzw. fehlerhafte Daten angezeigt werden. Primärer Ansprechpartner ist somit die jeweilige Fakultät.',
  QuestionSettings:
    'Warum werden meine Einstellungen (z.B. Mensa, Stundenplan) nicht gespeichert?',
  AnswerSettings:
    'In der aktuellen Version speichern wir keine Cookies und damit auch keine Einstellungen.',
  QuestionNotAnswered:
    'Konntest du keine Antwort auf deine Frage finden? Dann würden wir uns über eine E-Mail freuen:',
  AnswerMostImportant:
    'Hier findest du außerdem die wichtigsten Antworten auf deine Fragen rund um die HKA-APP:',
  ToFaq: 'Zum großen FAQ',
  // Timetable
  Calendar: 'Kalender',
  SelectStudyProgram: 'Wählen Sie bitte einen Studiengang aus',
  SelectSemester: 'Stundenplan wählen',
  SelectedSemester: 'Semester',
  GeneralDates: 'Semesterpläne',
  TaskListFreshman: 'Aufgabenplan',
  ResetTaskList: 'Aufgabenplan zurücksetzen',
  ResetText:
    'Beim Zurücksetzen, werden die von Ihnen verschobenen Termine des Aufgabenplans für Erstis wieder auf den Ursprungszustand zurückgesetzt. Möchten Sie dennoch zurücksetzen?',
  Cancel: 'Abbrechen',
  Reset: 'Zurücksetzen',
  Day: 'Tag',
  Week: 'Woche',
  Month: 'Monat',
  MoveDate: 'Termin verschieben',
  Done: 'Erledigt',
  Open: 'Öffnen',
  Move: 'Verschieben',
  DateMoved: 'Termin wurde erfolgreich verschoben',
  TaskCompleted: 'Aufgabe erledigt',
  TaskReopened: 'Aufgabe wieder geöffnet',
  NoData: 'Wählen Sie zuerst Studiengang aus',
  TimetableSelect: 'Stundenplan',
  Confirm: 'Bestätigen',
  InformationFirstSemester:
    'Aktuell kann aus datenschutzrechtlichen Gründen nur das erste Semester der jeweiligen Studiengänge angezeigt werden.',

  // Timetable for MMT
  Username: 'Nutzername',
  Password: 'Passwort',
  ShowMMTPlans: 'MMT-Pläne anzeigen',
  For: 'Für',
  Students: 'Studierende',
  InfoTextMMT:
    'Öffne den Link über den Button "MMT-Pläne anzeigen", um dir den Kalender auf der Untis-Webseite anzusehen.',
  InfoTextW: 'Der Kalender kann über die InfoTools abgerufen werden.',
  Course: 'Studiengang',
  Semester: 'Semester',

  // Learning Places
  DataIsLoadingMessage: 'Daten werden geladen',
  DataLoadingFailedMessage: 'Beim Laden der Daten ist ein Fehler aufgetreten',
  SearchForPlaces: 'Suche nach Lernplätzen',
  OpenLPsOnly: 'nur offene LP',
  AllLPs: 'Alle LP',
  Of: 'von',
  PlacesFree: 'Plätzen frei',
  OpeningHours: 'Öffnungszeiten',
  OpeningHoursInfo:
    'Vor und während der Prüfungszeit auch an Sonntagen und Feiertagen gemäß gesonderter Ankündigung geöffnet.',
  Building: 'Gebäude',
  Floor: 'Stockwerk',

  // Train Stations
  CloseStations: 'Standortnahe Haltestellen',
  CloseToUniStations: 'Hochschulnahe Haltestellen',
  SubbedStations: 'Abonnierte Haltestellen',
  Timetables: 'Fahrpläne',
  CityBus: 'Stadtbus',
  BUS: 'BUS',
  Nearby: 'In der Nähe',
  WholeNetwork: 'Im kompletten Netz',
  StationNameSearch: 'Haltestelle',
  Search: 'Suchen',

  // Feedback
  FeedbackText:
    'Wir, das Team hinter der HKA-APP, sind Studierende der Hochschule Karlsruhe. Wir entwickeln die App mit viel Hingabe und Sorgfalt. Dennoch kann es vorkommen, dass einmal etwas nicht funktioniert, wie es sollte. Für Feedback und Hinweise sind wir deshalb sehr dankbar, weil uns dies bei der Lösung der Probleme immens weiterhilft.',
  FeedbackRequest: 'Feedback oder Supportanfragen',
  Support: 'Support',

  // Image alt texts
  HKAAppLogo: 'HKA-APP Logo',
}
