export const namespaced = true

export interface Faculty {
  text: string
  value: string
}

export interface MenuItem {
  title: string
  path: string
  icon: string
}

export interface Link {
  title: string
  path: string
}
export interface State {
  menu: MenuItem[]
  links: Link[]
  faculties: string[]
  facultyList: Faculty[]
  selectedFaculty: string
  loading: boolean
}

const defaults: State = {
  menu: [
    { title: 'Freshman', path: '/ersti', icon: 'mdi-medical-bag' },
    { title: 'News', path: '/news', icon: 'mdi-rss' },
    { title: 'Campus', path: '/campus', icon: 'mdi-compass' },
    { title: 'People', path: '/people', icon: 'mdi-account-search' },
    { title: 'Canteen', path: '/canteen', icon: 'mdi-food' },
    {
      title: 'Timetable',
      path: '/timetable',
      icon: 'mdi-timetable',
    },
    {
      title: 'LearningPlaces',
      path: '/learning-places',
      icon: 'mdi-bank',
    },
    { title: 'TrainStations', path: '/station', icon: 'mdi-train' },
    { title: 'Feedback', path: '/feedback', icon: 'mdi-thumbs-up-down' },
  ],
  links: [
    { title: 'FAQ', path: '/faq' },
    { title: 'Impressum', path: '/impressum' },
  ],
  faculties: ['AB', 'EIT', 'IMM', 'IWI', 'MMT', 'W'],
  facultyList: [
    {
      text: 'Architektur und Bauwesen',
      value: 'AB',
    },
    {
      text: 'Elektro- und Informationstechnik\n',
      value: 'EIT',
    },
    {
      text: 'Informationsmanagement und Medien',
      value: 'IMM',
    },
    {
      text: 'Informatik und Wirtschaftsinformatik',
      value: 'IWI',
    },
    {
      text: 'Maschinenbau und Mechatronik',
      value: 'MMT',
    },
    {
      text: 'Wirtschaftswissenschaften',
      value: 'W',
    },
  ],
  selectedFaculty: localStorage.getItem('selectedFaculty'),
  loading: false,
}

export const mutations = {
  SET_FACULTY(state, response) {
    state.selectedFaculty = response
  },
  SET_LOADING(state, response) {
    state.loading = response
  },
}

export const actions = {
  setFaculty({ commit }, faculty) {
    commit('SET_FACULTY', faculty)
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
}

export const state = Object.assign({}, defaults)
