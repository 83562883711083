export default {
  welcome: 'Bienvenido',

  SelectLanguage: 'Idioma',

  // Toolbar
  News: 'Noticias',
  Freshman: 'Primer año',
  Campus: 'Campus',
  People: 'Personas',
  Canteen: 'Comedor',
  Timetable: 'Horario',
  LearningPlaces: 'Lugares de estudio',
  TrainStations: 'Estaciones de tren',
  InstallApp: 'Instalar aplicación?',
  Faculty: 'Facultad',
  FacultyShort: 'Fac',

  // CategoryMasonry
  NoEntryCategory: 'Lo sentimos, no hay entradas en esta categoría',

  // FormGenerator
  Other: 'Otros',

  // FAQ
  RequestQuestion: 'Why does it take so long for my request to be processed',
  RequestAnswer:
    'The support team of HKA-APP is very anxious to give you a qualitative and helpful answer to your request as soon as possible. We ask for your patience because HKA-APP is a small team of students who realize the project parallel to their studies. Due to the continuous re-staffing of the respective areas after each semester, additional longer processing times may occur.',
  BrowserQuestion: 'Which Browser can i use for the Web App',
  BrowserAnswer:
    'The Web App runs on every browser that supports JavaScript: Google Chrome, Mozilla Firefox and Microsoft Edge ',
  ServerQuestion:
    'When requesting server data, I get an error. What should I do',
  ServerAnswer:
    'Wait a few hours. It is probably a server error. Otherwise, send us an email to info@hskampus.de or contact us via the feedback tab in the footer',
  QuestionPersonData:
    'Why are some room numbers and data about persons incorrect?',
  AnswerPersonData:
    'The data is forwarded from the LSF server of Karlsruhe University of Applied Sciences. Unfortunately, they may not be updated promptly from time to time.',
  QuestionTimetable: 'Why is my timetable not available or not selectable',
  AnswerTimetable:
    'HKA-APP only serves as an intermediary, which means that we qualitatively process the data that we receive from various sources. We, therefore, do not influence the sources, so it may be that no or incorrect data is displayed. Please get in touch with the respective faculty.',
  QuestionSettings: 'Why are my settings not persisted??',
  AnswerSettings:
    'In the current version we save no cookies and therefore no settings.',
  QuestionNotAnswered:
    'Couldn not find an answer to your question? Then we would be happy to receive an e-mail:',
  AnswerMostImportant:
    'Here you will also find the most important answers to your questions about the HKA APP:',
  ToFaq: 'To the detailed FAQ',

  // News Page
  FilterNews: 'Filtrar noticias',
  SearchNews: 'Buscar noticias',
  Reports: 'Informes',
  Press: 'Pulsar',
  ToArticle: 'Ir al articulo',
  PublishedAt: 'Publicado el',
  Feedback: 'Reacción',
  Impressum: 'Imprimir',
  FAQ: 'FAQ',

  // Campus
  General: 'General',
  Office: 'Oficina',
  StudentAssociation: 'Asociación de estudiantes',

  // Office Card
  Room: 'Salón',
  Phone: 'Teléfono',
  ConsultationHours: 'Horarios de oficina',
  LearnMore: 'Más información',
  Edit: 'Editar',
  Close: 'Cerrar',
  LastModified: 'Modificado por última vez',

  // People
  ChooseFaculty: 'Seleccionar facultad',
  SearchForPeople: 'Buscar personas',

  // edit

  PersonEdit: 'Editar persona',
  RequestChange: 'Solicitar cambio',

  // Canteen
  SelectLocation: 'Seleccione la ubicación',
  SelectRole: 'Seleccione la función',
  SelectDate: 'Seleccione la fecha',
  LocationClosed: 'No hay información para este día',
  Closed: 'Cerrado',
  Today: 'Hoy',
  Undergraduate: 'Estudiantes',
  Guest: 'Invitado',
  Student: 'Estudiante',
  Employee: 'Empleado',

  Wahlessen1: 'Cena optativa 1',
  Wahlessen2: 'Cena optativa 2',
  Wahlessen3: 'Cena optativa 3',
  Linie1: 'Línea 1',
  Linie2: 'Línea 2',
  Linie3: 'Línea 3',
  Linie45: 'Línea 4/5',
  L6Update: 'Línea 6',
  CurryQueen: 'Curry Queen',
  GutGünstig: 'Bueno y barato',
  Schnitzelbar: 'Barra de escalopes',
  Aktionstheke: 'Menú en oferta',
  wahl3: 'Opción 3',
  GutGünstig2: 'Bueno y barato 2',
  Cafeteriaab1430: 'Cafetería abierta a partir de las 14:30',
  CafeteriaHeißeTheke: 'Bar de bebidas calientes',
  Abend: 'Por la noche',
  pizza: 'Pizza',
  pasta: 'Pasta',
  Buffet: 'Bufé',
  salat_dessert: 'Ensalada / Postre',

  // Timetable
  Calendar: 'Calendario',
  SelectStudyProgram: 'Seleccione un programa de estudios',
  SelectedSemester: 'Semestre',
  SelectSemester: 'Select Timetable',
  GeneralDates: 'Fechas generales',
  TaskListFreshman: 'Lista de tareas para el primer año',
  ResetTaskList: 'Reiniciar la lista de tareas',
  ResetText:
    'Cuando reinicie el horario, las citas que haya movido en la lista de tareas para los estudiantes de primer año, volverán al estado original. Volver al estado original. ¿Todavía desea reiniciar?',
  Cancel: 'Cancelar',
  Reset: 'Reiniciar',
  Day: 'Día',
  Week: 'Semana',
  Month: 'Mes',
  MoveDate: 'Aplazar la fecha',
  Done: 'Hecho',
  Open: 'Abierto',
  Move: 'Mover',
  DateMoved: 'La fecha fue pospuesta con éxito',
  TaskCompleted: 'Tarea completada',
  TaskReopened: 'Tarea reabierta',
  NoData: 'Primero seleccione un curso',
  TimetableSelect: 'Horario',
  Confirm: 'Confirmar',
  // TODO
  InformationFirstSemester:
    'For data protection reasons, only the first semester of the respective degree program can currently be displayed.',

  // Timetable for MMT
  Username: 'Nombre de usuario',
  Password: 'Contraseña',
  ShowMMTPlans: 'Mostrar planes MMT',
  For: 'Para',
  Students: 'Estudiantes',
  InfoTextMMT:
    'Abra el enlace a través del botón "Ver horarios de MMT" para ver el calendario en el sitio web de Untis.',
  InfoTextW: 'Se puede acceder al calendario a través de InfoTools.',

  // Learning Places
  SearchForPlaces: 'Buscar lugares de estudio',
  OpenLPsOnly: 'Sólo LPs abiertos',
  AllLPs: 'Todos los LPs',
  Of: 'de ',
  PlacesFree: 'Plazas libres',
  OpeningHours: 'Horario de apertura',
  OpeningHoursInfo:
    'Antes y durante el periodo de exámenes, las oficinas también están abiertas los domingos y los días festivos.(Con previo aviso)',
  Building: 'Construyendo',
  Floor: 'Piso',

  // Train Stations
  CloseStations: 'Paradas cercanas al lugar',
  CloseToUniStations: 'Paradas cerca de la universidad',
  SubbedStations: 'Emisoras suscritas',
  Timetables: 'Horarios',
  CityBus: 'Autobús urbano',
  BUS: 'BUS',
  Nearby: 'Cercano',
  WholeNetwork: 'En toda la red',
  StationNameSearch: 'parada de autobús',
  Search: 'Buscar',

  // TODO Feedback
  FeedbackText:
    'We, the team behind HKA-APP, are students of the Karlsruhe University of Applied Sciences. We develop this app with care and dedication. Yet, errors can happen. That is why we are grateful for any feedback, the contribution of our users really helps us making the product better.',
  FeedbackRequest: 'Feedback or support request',
  Support: 'Support',
}
