import { Plugin } from '@nuxt/types'
import Fuse from 'fuse.js'

declare module 'vue/types/vue' {
  interface Vue {
    $fuse<T>(
      list: ReadonlyArray<T>,
      options: Fuse.IFuseOptions<T>,
      index?: Fuse.FuseIndex<T>
    ): Fuse<T>
  }
}

export default ((_, inject) => {
  inject('fuse', (list, options, index) => new Fuse(list, options, index))
}) as Plugin
