import Vue from 'vue'

Vue.filter('formatUnixTimestamp', (value) => {
  if (value) {
    const date = new Date(value * 1000)

    return date.toLocaleDateString('de-DE')
  }

  return null
})

Vue.filter('formatDate', (value) => {
  if (value) {
    const date = new Date(value)

    return date.toLocaleDateString('de-DE')
  }

  return null
})
