import { Plugin } from '@nuxt/types'
import { orderBy, filter, groupBy, truncate } from 'lodash'

class LoDash {
  orderBy = orderBy
  filter = filter
  groupBy = groupBy
  truncate = truncate
}

declare module 'vue/types/vue' {
  interface Vue {
    $lodash: LoDash
  }
}

export default ((_, inject) => {
  inject('lodash', new LoDash())
}) as Plugin
